<script setup>
import { twMerge } from '@/Composables/External/twMerge'
import { computed } from 'vue'

const props = defineProps({
    class: {
        type: String,
        default: '',
    },
})

const classNames = computed(() => {
    return twMerge('grid auto-rows-auto grid-cols-4 gap-x-4 md:grid-cols-12 md:gap-x-8', props.class)
})
</script>
<template>
    <div :class="classNames">
        <slot></slot>
    </div>
</template>
