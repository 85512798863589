<script setup>
import { computed } from 'vue'
import { twMerge } from '@/Composables/External/twMerge.js'

const props = defineProps({
    class: {
        type: String,
    },
})

const classNames = computed(() => {
    return twMerge(['rounded-lg', props.class])
})
</script>

<template>
    <tr :class="classNames">
        <slot></slot>
    </tr>
</template>
