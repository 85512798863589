import { createPinia } from 'pinia'
import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import router from '@/Plugins/router.js'
import i18n from './Plugins/i18n'

import 'swiper/css'
import '@vuepic/vue-datepicker/dist/main.css'
import * as Sentry from '@sentry/vue'

const pinia = createPinia()

createInertiaApp({
    progress: { color: '#ffeb7a' },
    resolve: (name) => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })

        return pages[`./Pages/${name}.vue`]
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            integrations: [
                Sentry.replayIntegration({
                    maskAllText: false,
                }),
            ],
        })

        app.use(plugin).use(router).use(i18n).use(pinia).mount(el)

        return app
    },
})
