import axios from 'axios'
import { ref } from 'vue'

export function useFetch(url, { config, onSuccess, onError } = {}) {
    const data = ref(null)
    const error = ref(null)
    const loading = ref(false)

    const onSucceed = (response) => {
        onSuccess?.(response)
        data.value = response?.data
        loading.value = false

        return response
    }

    const onErrors = (err) => {
        loading.value = false
        error.value = `${err?.message}: ${err?.response?.data?.message}`
        onError?.(err)
    }

    const post = (formData = {}) => {
        data.value = null
        error.value = null
        loading.value = true

        const option = config || {}

        return axios.post(url, formData, option).then(onSucceed).catch(onErrors)
    }

    const get = (params) => {
        data.value = null
        error.value = null
        loading.value = true
        const option = config || {}

        return axios
            .get(url, {
                params,
                ...option,
            })
            .then(onSucceed)
            .catch(onErrors)
    }

    return { data, error, loading, post, get }
}
