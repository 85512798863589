<script setup>
import { computed } from 'vue'

import { Link, usePage } from '@inertiajs/vue3'

import Panel from '@/Components/Surfaces/Card/Panel.vue'
import RoundIcon from '@/Components/Icons/RoundIcon/RoundIcon.vue'
import IconFactory from '@/Components/Icons/IconFactory.vue'

const page = usePage()

const isActive = computed(
    () => (url) =>
        url
            .split('/')
            .findLast((element) => element)
            .startsWith(
                page.url
                    .split('/')
                    .findLast((element) => element)
                    .split('?')
                    .find((element) => element),
            ),
)

const menuNavigationItems = computed(() => page.props.layoutElements.myAccountNavigation)
</script>

<template>
    <nav aria-label="Account">
        <Panel>
            <ul>
                <li v-for="({ value, name, data }, index) in menuNavigationItems" :key="index" class="mb-2">
                    <Link
                        :href="value"
                        class="group flex items-center gap-4 text-neutral-900 no-underline hover:no-underline"
                        :class="[isActive(value) ? 'text-primary' : 'font-light']"
                        preserve-scroll>
                        <RoundIcon
                            :class="[isActive(value) ? 'bg-primary text-white' : 'bg-transparent group-hover:bg-primary-100']"
                            class="size-8 transition-all duration-200 ease-in-out">
                            <IconFactory :icon="data.icon" />
                        </RoundIcon>

                        {{ name }}
                    </Link>
                </li>
            </ul>
        </Panel>
    </nav>
</template>
