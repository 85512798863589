<script setup>
import Button from '@/Components/Forms/Buttons/Button.vue'
import { isEmpty } from 'lodash'
import { computed } from 'vue'
import UrlWrapper from '@/Components/Miscellaneous/UrlWrapper.vue'

const props = defineProps({
    image: {
        type: String,
        required: true,
    },
    title: {
        type: String,
        required: true,
    },
    body: {
        type: String,
        required: true,
    },
    buttonText: {
        type: String,
    },
    buttonUrl: {
        type: String,
    },
    orientation: {
        type: String,
        default: 'portrait',
    },
    url: {
        type: String,
        required: false,
    },
    imageContain: {
        type: Boolean,
    },
    isProduct: {
        type: Boolean,
        default: false,
    },
})

const hasButton = computed(() => {
    return !isEmpty(props.buttonText) && !isEmpty(props.buttonUrl)
})
</script>

<template>
    <div
        class="group flex h-full flex-col overflow-hidden rounded-3xl border-neutral-200 transition-all duration-300 ease-in-out focus-within:ring-2 focus-within:ring-primary-200 focus-within:ring-offset-2 hocus:-translate-y-2"
        :class="{ 'md:flex-row': orientation === 'landscape' }">
        <div
            class="relative inline-flex aspect-[16/10] items-center justify-center overflow-hidden"
            :class="[
                {
                    'md:inline-flex md:w-1/3': orientation === 'landscape',
                    'bg-neutral-200': !isProduct,
                },
                isProduct ? 'overflow-hidden rounded-t-3xl border border-b-0 border-neutral-200 bg-white py-4' : 'bg-neutral-200',
            ]">
            <UrlWrapper :url="url" class="size-full">
                <img
                    :src="image"
                    :alt="title"
                    :class="[imageContain ? 'object-contain' : 'object-cover']"
                    class="aspect-[16/10] size-full object-center transition-all duration-300 ease-in-out group-hover:scale-105" />
            </UrlWrapper>
        </div>
        <div
            class="flex flex-1 flex-col bg-accent p-4 text-center md:p-6"
            :class="{ 'justify-center md:w-2/3 md:text-left': orientation === 'landscape' }">
            <h4>
                <UrlWrapper :url="url">
                    {{ title }}
                </UrlWrapper>
            </h4>

            <UrlWrapper :url="url">
                <div v-html="body"></div>
            </UrlWrapper>

            <slot name="body"></slot>

            <Button
                v-if="hasButton"
                class="mt-4 inline-block self-center md:mt-6"
                :class="{ 'md:self-start': orientation === 'landscape' }"
                :url="buttonUrl">
                {{ buttonText }}
            </Button>
        </div>
    </div>
</template>
