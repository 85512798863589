<script setup>
import { computed } from 'vue'
import Plus from '@/Components/Icons/Plus.vue'
import Minus from '@/Components/Icons/Minus.vue'

const props = defineProps({
    min: {
        type: Number,
        default: 0,
    },
    max: {
        type: Number,
    },
    step: {
        type: Number,
        default: 1,
    },
    modelValue: {
        type: Number,
    },
    id: {
        type: String,
        default: 'quantity',
    },
    name: {
        type: String,
        default: 'quantity',
    },
    isSmall: {
        type: Boolean,
    },
})

const emit = defineEmits(['update:modelValue'])

const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    },
})

const clampValue = (value, min, max) => {
    if (value < min) return min
    if (value > max) return max

    return value
}

const decrease = () => {
    const newValue = value.value - props.step
    value.value = clampValue(newValue, props.min, props.max)
}

const increase = () => {
    const newValue = value.value + props.step
    value.value = clampValue(newValue, props.min, props.max)
}

const checkValue = () => {
    value.value = clampValue(value.value, props.min, props.max)
}
</script>

<template>
    <div class="flex w-full items-center justify-between gap-2 rounded-full border border-primary p-1.5">
        <button
            type="button"
            @click.prevent="decrease"
            :class="[isSmall ? 'h-5' : 'h-10']"
            class="inline-flex aspect-square h-10 items-center justify-center rounded-full bg-white text-neutral-900 hover:bg-neutral-300">
            <Minus class="size-3" />
            <span class="sr-only">Minus</span>
        </button>
        <input
            :id="id"
            :name="name"
            type="number"
            :class="[isSmall ? 'text-base' : 'text-2xl']"
            class="w-full appearance-none border-none bg-transparent p-0 text-center font-medium focus:border-0 focus:outline-0 focus:ring-0"
            :min="min"
            :step="step"
            :max="max"
            v-model="value"
            @change="checkValue"
            @input="$emit('update:modelValue', $event.target.value)" />
        <button
            type="button"
            @click.prevent="increase"
            :class="[isSmall ? 'h-5' : 'h-10']"
            class="inline-flex aspect-square items-center justify-center rounded-full bg-white text-neutral-900 hover:bg-neutral-300">
            <Plus class="size-3" />
            <span class="sr-only">Plus</span>
        </button>
    </div>
</template>
