<script setup>
import Check from '@/Components/Icons/Check.vue'
import Cross from '@/Components/Icons/Cross.vue'
import RoundIcon from '@/Components/Icons/RoundIcon/RoundIcon.vue'
import { onMounted, ref } from 'vue'

const emits = defineEmits(['toast-hidden'])
const timeoutTime = 1e4 // 10 Seconds

const props = defineProps({
    visible: {
        type: Boolean,
        required: true,
    },
    position: {
        type: Number,
        required: false,
        default: 1,
    },
    success: {
        type: Boolean,
        required: true,
    },
})

const visible = ref(props.visible)

function hide() {
    visible.value = false
    emits('toast-hidden')
}

onMounted(() => setTimeout(() => hide(), timeoutTime))
</script>

<template>
    <Transition name="slide-fade">
        <div v-show="visible" @click="hide" class="mb-3 cursor-pointer rounded border border-gray-200 bg-white p-4 shadow-xl" role="alert">
            <div class="flex items-center">
                <RoundIcon v-if="success" class="mr-3 size-6 bg-primary text-white"><Check class="size-4" /></RoundIcon>
                <RoundIcon v-if="!success" class="mr-3 size-6 bg-danger text-white"><Cross class="size-4" /></RoundIcon>

                <span class="text-sm font-light"><slot></slot></span>
            </div>
        </div>
    </Transition>
</template>

<style>
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.25s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(25%);
    opacity: 0;
}
</style>
