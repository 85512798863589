import route from 'ziggy-js'

export default {
    install: (app, _options) => {
        const routerMethod = function (name, params = {}, locale = window.locale, absolute = true) {
            if (name.toLowerCase() === 'storefront') {
                return '/' + locale
            }

            try {
                // eslint-disable-next-line no-undef
                return route(`${locale}.${name}`, params, absolute, Ziggy).toString()
            } catch (error) {
                // Try non-localized
            }

            // eslint-disable-next-line no-undef
            return route(name, params, absolute, Ziggy).toString()
        }

        app.config.globalProperties.$route = routerMethod
        app.provide('route', routerMethod)
    },
}
