<script setup>
import { isNil, uniqueId } from 'lodash'
import { onMounted } from 'vue'

const props = defineProps({
    hubspotFormId: {
        type: String,
    },
})

const id = uniqueId('hubspot-form-id-')

const createScript = () => {
    if (isNil(props.hubspotFormId)) {
        return
    }

    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
        if (window.hbspt) {
            window.hbspt.forms.create({
                region: 'eu1',
                portalId: '26934989',
                formId: props.hubspotFormId,
                target: `#${id}`,
                cssRequired: '',
            })
        }
    })
}

onMounted(() => createScript())
</script>

<template>
    <div :id="id"></div>
</template>
