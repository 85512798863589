<script setup>
import Lock from '@/Components/Icons/Lock.vue'
import usePriceFormatter from '@/Composables/usePriceFormatter.js'
import PricingTable from '@/View/Category/PricingTable.vue'
import AddToCart from '@/View/Product/AddToCart.vue'
import DocumentsList from '@/View/Product/DocumentsList.vue'
import StockAvailability from '@/View/Product/StockAvailability.vue'
import Button from '@/Components/Forms/Buttons/Button.vue'
import useBreakpoint from '@/Composables/useBreakpoint.js'
import { first, isNil, orderBy } from 'lodash'
import { computed, onMounted, ref } from 'vue'

const { breakpoints } = useBreakpoint()

const props = defineProps({
    inStock: {
        type: Boolean,
        required: true,
    },
    documents: {
        type: Array,
        required: false,
    },
    isSmall: {
        type: Boolean,
        default: null,
    },
    productSku: {
        type: String,
        required: true,
    },
    pricing: {
        type: [Array, null],
        default: null,
    },
    inProductFeed: {
        type: Boolean,
        default: false,
    },
})

const priceFormatter = usePriceFormatter()
const pricePerPiece = ref(0)
const quantity = ref(1)
const packingUnit = ref(null)
const itemsPerPackingUnit = ref(null)
const hasPricing = computed(() => !isNil(props.pricing))

const hasPackingUnit = computed(() => {
    return !isNil(packingUnit.value)
})

const totalAmountOfItems = computed(() => quantity.value * itemsPerPackingUnit.value)

const onChangeQuantity = (quantityValue, pricingObject) => {
    quantity.value = quantityValue
    packingUnit.value = pricingObject.packingUnit
    itemsPerPackingUnit.value = pricingObject.itemsPerPackingUnit

    setPricePerPiece(quantityValue)
}

const setPricePerPiece = (quantityValue) => {
    const priceObject = props.pricing.find((item) => item.packingUnit === packingUnit.value)
    const tierPrices = orderBy(priceObject.tierPrices, 'quantity', 'desc')
    const tier = tierPrices.find((item) => quantityValue >= item.quantity)

    pricePerPiece.value = tier.price
    quantity.value = quantityValue
}

const setDefaultPrice = () => {
    if (!hasPricing.value) {
        return
    }

    let selectedPackingUnit = props.pricing.find((item) => item.defaultPackingUnit)

    if (isNil(selectedPackingUnit)) {
        selectedPackingUnit = first(orderBy(props.pricing, 'itemsPerPackingUnit', 'asc'))
    }

    const pricing = first(orderBy(selectedPackingUnit.tierPrices, 'quantity', 'asc'))

    packingUnit.value = selectedPackingUnit.packingUnit
    itemsPerPackingUnit.value = selectedPackingUnit.itemsPerPackingUnit
    pricePerPiece.value = pricing.price
    quantity.value = pricing.quantity
}

onMounted(() => {
    setDefaultPrice()
})
</script>

<template>
    <div v-if="$page.props.auth.isLoggedIn" class="mt-5 flex flex-1 flex-col justify-between">
        <StockAvailability
            :in-stock="inStock"
            :pilledShape="isSmall ?? breakpoints.down('sm')"
            :class="{ 'md:justify-start': !isSmall }"
            class="justify-center" />
        <DocumentsList v-if="documents" :documents="documents" class="order-last lg:order-none" />
        <div v-if="inStock && hasPackingUnit && hasPricing">
            <PricingTable :in-product-feed="inProductFeed" :pricing="pricing" :onClick="onChangeQuantity" />
            <AddToCart
                :price="pricePerPiece"
                :packing-unit="packingUnit"
                :product-sku="productSku"
                :quantity="quantity"
                @quantity-changed="setPricePerPiece"
                :is-small="isSmall ?? breakpoints.down('sm')">
                <template v-slot:description>
                    <p class="my-2 text-center text-gray-500">
                        {{
                            $trans.choice('view.product.add-to-cart.items.summary', itemsPerPackingUnit, {
                                total: totalAmountOfItems,
                                pricePerPiece: priceFormatter.format(pricePerPiece),
                                itemsPerPackingUnit: itemsPerPackingUnit,
                            })
                        }}
                    </p>
                </template>
            </AddToCart>
        </div>

        <div v-if="!inStock && hasPackingUnit && hasPricing" class="mt-6 bg-gray-100 p-4 text-left">
            <p class="mb-4">{{ $trans.get('view.product.in-stock.description') }}</p>

            <p class="text-lg font-bold">
                {{ priceFormatter.format(pricePerPiece) }}

                <span class="lowercase">
                    {{
                        $trans.choice('view.product.per-piece', itemsPerPackingUnit, {
                            pieces: itemsPerPackingUnit,
                        })
                    }}
                </span>
            </p>
        </div>
    </div>
    <div v-else class="mt-5 flex flex-1 flex-col justify-end">
        <Button disabled class="w-full">
            {{ $trans.get('view.product.add-to-cart') }}
            <template v-slot:end>
                <Lock class="size-6 !fill-none" />
            </template>
        </Button>
        <a :href="$route('auth.login.form')" class="mt-4 block text-center text-base no-underline hover:underline">
            {{ $trans.get('product.card.login_notification') }}
        </a>
    </div>
</template>

<style scoped></style>
