<script setup>
import { twMerge } from '../../../Composables/External/twMerge.js'
import { computed } from 'vue'

const props = defineProps({
    class: {
        type: String,
        default: '',
    },
})

const classNames = computed(() => {
    return twMerge('container mx-auto', props.class)
})
</script>
<template>
    <div :class="classNames">
        <slot></slot>
    </div>
</template>
