<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" fill="currentcolor">
        <path
            d="M14.2367 19.4189C12.5866 19.4189 11.2666 20.7189 11.2666 22.3439C11.2666 23.9689 12.5866 25.2689 14.2367 25.2689C15.8867 25.2689 17.2067 23.9689 17.2067 22.3439C17.2067 20.7596 15.8454 19.4189 14.2367 19.4189ZM14.2367 23.4814C13.6179 23.4814 13.1229 22.9939 13.1229 22.3846C13.1229 21.7752 13.6179 21.2877 14.2367 21.2877C14.8554 21.2877 15.3504 21.7752 15.3504 22.3846C15.3504 22.9533 14.8142 23.4814 14.2367 23.4814Z" />
        <path
            d="M6.39902 19.4189C4.74898 19.4189 3.42896 20.7189 3.42896 22.3439C3.42896 23.9689 4.74898 25.2689 6.39902 25.2689C8.04905 25.2689 9.36908 23.9689 9.36908 22.3439C9.36908 20.7596 8.0078 19.4189 6.39902 19.4189ZM6.39902 23.4814C5.78026 23.4814 5.28524 22.9939 5.28524 22.3846C5.28524 21.7752 5.78026 21.2877 6.39902 21.2877C7.01778 21.2877 7.51279 21.7752 7.51279 22.3846C7.51279 22.9533 7.01778 23.4814 6.39902 23.4814Z" />
        <path
            d="M22.9406 0.731445H20.7131C19.723 0.731445 18.8568 1.4627 18.733 2.4377L18.073 7.10957H1.77891C1.3664 7.10957 0.95389 7.3127 0.665134 7.63769C0.417628 7.96269 0.293876 8.40957 0.417628 8.81582C0.417628 8.85645 0.417628 8.85645 0.417628 8.89707L2.97518 16.4939C3.14019 17.0627 3.67645 17.4689 4.29521 17.4689H15.6805C17.2067 17.4689 18.5268 16.3314 18.733 14.8283L20.4656 2.68145C20.4656 2.6002 20.5481 2.55957 20.6306 2.55957H22.8581C23.3531 2.55957 23.8069 2.15332 23.8069 1.6252C23.8069 1.09707 23.4356 0.731445 22.9406 0.731445ZM16.9592 14.5439C16.8767 15.1533 16.3405 15.6002 15.7217 15.6002H4.66647L2.43892 8.93769H17.7842L16.9592 14.5439Z" />
    </svg>
</template>
