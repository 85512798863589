import Lang from 'lang.js'

export default {
    install: (app) => {
        const transMethod = new Lang({
            messages: window.messages ?? {},
            locale: window.locale ?? 'en',
            fallback: 'en',
        })

        app.config.globalProperties.$trans = transMethod
        app.provide('trans', transMethod)
    },
}
