<script setup>
import Button from '@/Components/Forms/Buttons/Button.vue'
import Trash from '@/Components/Icons/Trash.vue'
import Row from '@/Components/Surfaces/Grid/Row.vue'
import usePriceFormatter from '@/Composables/usePriceFormatter.js'
import CartLine from '@/View/Cart/CartLine.vue'
import { router } from '@inertiajs/vue3'
import { computed, inject } from 'vue'

const props = defineProps({
    grandTotal: {
        type: Number,
        required: true,
    },
    subtotal: {
        type: Number,
        required: true,
    },
    items: {
        type: Array,
        required: false,
    },
    itemCount: {
        type: Number,
        required: false,
    },
})

const route = inject('route')
const priceFormatter = usePriceFormatter()
const hasCartItems = computed(() => props.itemCount > 0)

const handleTruncateCart = () => {
    router.get(route('cart.truncate'))
}
</script>
<template>
    <Row>
        <section aria-labelledby="cart-heading" class="col-span-full lg:col-span-8">
            <h2 id="cart-heading" class="sr-only">{{ $trans.get('pages.cart.list.title') }}</h2>

            <template v-if="hasCartItems">
                <ul class="lg:divide-y lg:divide-accent" role="list">
                    <CartLine v-for="item in items" :key="item.id" :item="item" />
                </ul>

                <div class="mt-4">
                    <Button id="truncate-cart" color="danger" @click="handleTruncateCart">
                        <template v-slot:start>
                            <Trash />
                        </template>
                        {{ $trans.get('pages.cart.buttons.truncate-cart') }}
                    </Button>
                </div>
            </template>

            <template v-if="!hasCartItems">
                <p class="mb-4">{{ $trans.get('pages.cart.empty.text') }}</p>

                <Button id="home-button" :url="$route('storefront')">{{ $trans.get('pages.cart.buttons.empty') }}</Button>
            </template>
        </section>

        <!-- Order summary -->
        <section aria-labelledby="summary-heading" class="col-span-full mt-4 md:mt-0 lg:col-span-4">
            <div class="sticky top-8 rounded-lg bg-accent px-4 py-6 sm:p-6 lg:p-8">
                <h2 id="summary-heading" class="text-2xl text-gray-900">{{ $trans.get('pages.cart.order-summary.title') }}</h2>

                <dl class="mt-6 space-y-4">
                    <div class="flex items-center justify-between pt-4">
                        <dt class="text-base font-medium text-gray-900">{{ $trans.get('pages.cart.order-summary.subtotal') }}</dt>
                        <dd class="text-base font-medium text-gray-900">{{ priceFormatter.format(subtotal) }}</dd>
                    </div>

                    <div class="flex items-center justify-between border-t border-gray-200 pt-4">
                        <dt class="text-base font-medium text-gray-900">{{ $trans.get('pages.cart.order-summary.order-total') }}</dt>
                        <dd class="text-base font-medium text-gray-900">{{ priceFormatter.format(grandTotal) }}</dd>
                    </div>
                </dl>

                <div class="mt-6">
                    <Button id="checkout-button" :disabled="!hasCartItems" :url="$route('checkout.form')" full-width="all">
                        {{ $trans.get('pages.cart.order-summary.checkout') }}
                    </Button>
                </div>
            </div>
        </section>
    </Row>
</template>
