<script setup>
// ** Vue imports
import { ref } from 'vue'

// ** Inertiajs imports
import { Head } from '@inertiajs/vue3'

// ** Components imports
import Row from '@/Components/Surfaces/Grid/Row.vue'
import Container from '@/Components/Surfaces/Grid/Container.vue'

// ** View imports
import CheckoutForm from '@/View/Checkout/Form/CheckoutForm.vue'
import MasterLayout from '@/View/Layouts/MasterLayout.vue'

defineProps({
    pageTitle: {
        type: String,
        required: true,
    },
    metaDescription: {
        type: String,
        required: false,
    },
    cart: {
        type: Object,
    },
    countries: {
        type: Array,
        required: true,
    },
})

const deliveryDate = ref('')

const handleDeliveryDateChanged = (payload) => {
    deliveryDate.value = payload
}
</script>
<template>
    <Head>
        <title>{{ pageTitle }}</title>
        <meta v-if="metaDescription" :content="metaDescription" name="description" />
    </Head>
    <MasterLayout>
        <Container>
            <Row class="space-y-6">
                <div class="col-span-full flex flex-col items-center xs:flex-row xs:gap-6">
                    <h1 class="mb-0">{{ pageTitle }}</h1>
                </div>
            </Row>
            <CheckoutForm :cart="cart" :countries="countries" class="mt-10 md:mt-20" @delivery-date-changed="handleDeliveryDateChanged" />
        </Container>
    </MasterLayout>
</template>
