<script setup>
import { useSlots, computed } from 'vue'
import { Link } from '@inertiajs/vue3'
import { twMerge } from '@/Composables/External/twMerge'

const slots = useSlots()

const props = defineProps({
    type: {
        type: String,
        default: 'button',
    },
    color: {
        type: String,
        default: 'default',
    },
    fullWidth: {
        type: String,
        required: false,
    },
    url: {
        type: String,
        required: false,
    },
    disabled: {
        type: Boolean,
        default: false,
        required: false,
    },
    preserveScroll: {
        type: Boolean,
        required: false,
    },
})

const color = computed(() => {
    const { color } = props

    return {
        isPrimary: color === 'primary',
        isSecondary: color === 'secondary',
        isOutlined: color === 'outlined',
        isOutlinedLight: color === 'outlined-light',
        isTransparent: color === 'transparent',
        isWhite: color === 'white',
        isInfo: color === 'info',
        isDanger: color === 'danger',
        isWarning: color === 'warning',
        isSuccess: color === 'success',
    }
})

const fullWidth = computed(() => {
    const { fullWidth } = props

    return {
        fw: fullWidth === 'all',
        fwOnXs: fullWidth === 'xs',
        fwOnSm: fullWidth === 'sm',
        fwOnMd: fullWidth === 'md',
        fwOnLg: fullWidth === 'lg',
        fwOnXl: fullWidth === 'xl',
    }
})

const classNames = computed(() => {
    if (props.disabled) {
        return 'text-neutral-500 bg-neutral-200 pointer-events-none'
    }

    if (color.value.isSecondary) {
        return 'bg-secondary text-neutral-900 hocus:bg-neutral-900 hocus:text-secondary group-hover:bg-neutral-900 group-hover:text-secondary focus:ring-secondary-100'
    }

    if (color.value.isOutlined) {
        return 'bg-white text-primary border-primary border hocus:bg-primary hocus:text-white group-hover:bg-primary group-hover:text-primary'
    }

    if (color.value.isOutlinedLight) {
        return 'bg-neutral-1000 text-neutral-900 border-neutral-300 hocus:bg-neutral-300 hocus:text-neutral-900 group-hover:bg-neutral-300 group-hover:text-neutral-900 focus:ring-neutral-300'
    }

    if (color.value.isTransparent) {
        return 'bg-transparent text-neutral-1000 border-neutral-1000 hocus:bg-neutral-1000 hocus:text-neutral-900 group-hover:bg-neutral-1000 group-hover:text-neutral-900 focus:ring-neutral-300'
    }

    if (color.value.isWhite) {
        return 'bg-white text-primary hocus:bg-neutral-900 hocus:text-white group-hover:bg-neutral-900 group-hover:text-white'
    }

    if (color.value.isInfo) return 'bg-info-400 text-info-700 hocus:text-white hocus:bg-black'
    if (color.value.isDanger) return 'bg-danger-300 text-danger-800 hocus:text-white hocus:bg-black'
    if (color.value.isWarning) return 'bg-warning-300 text-warning-800 hocus:text-white hocus:bg-black'
    if (color.value.isSuccess) return 'bg-success-300 text-success-800 hocus:text-white hocus:bg-black'

    return ''
})

const isExternal = computed(() => {
    const value = props.url
    const https = value.includes('https://') || value.includes('http://')

    return value && https && !value.includes('shop.denimsolar')
})

const getType = computed(() => {
    if (props.url && isExternal.value) return 'a'
    if (props.url && !isExternal.value) return Link

    return 'button'
})
</script>

<template>
    <component
        :is="getType"
        :href="url"
        target="_blank"
        :type="type"
        :preserve-scroll="preserveScroll"
        :disabled="disabled"
        :class="[
            {
                // width options
                'w-full': fullWidth.fw,
                'w-full xs:w-auto': fullWidth.fwOnXs,
                'w-full sm:w-auto': fullWidth.fwOnSm,
                'w-full md:w-auto': fullWidth.fwOnMd,
                'w-full lg:w-auto': fullWidth.fwOnLg,
                'w-full xl:w-auto': fullWidth.fwOnXl,
                'flex': slots.start || slots.end,
            },
            twMerge(
                `
                group
                items-center
                justify-center
                rounded-full
                border-0
                bg-primary
                fill-white
                px-6
                py-2
                text-white
                transition-all
                duration-200
                focus:ring
                focus:ring-primary-100
                focus:ring-offset-2
                hocus:bg-primary-100
                hocus:text-black`,
                'text-center',
                'hocus:no-underline',
                classNames,
            ),
        ]">
        <span
            v-if="slots.start"
            class="mr-3"
            :class="[
                'size-4 [&>*]:size-full',
                {
                    'fill-neutral-500': disabled,
                    'fill-white': color.isPrimary || color.isSecondary || color.isInfo || color.isDanger || color.isWarning || color.isSuccess,
                    'fill-neutral-900': color.isWhite || color.isOutlinedLight || color.isOutlined,
                    'group-hover:fill-neutral-900 group-focus:fill-neutral-900': color.isSecondary || color.isTransparent,
                    'group-hover:fill-neutral-1000 group-focus:fill-neutral-1000':
                        color.isOutlined || color.isWhite || color.isInfo || color.isWarning || color.isSuccess || color.isDanger,
                    'fill-info-700': color.isInfo,
                    'fill-success-800': color.isSuccess,
                    'fill-warning-800': color.isWarning,
                    'fill-danger-800': color.isDanger,
                },
            ]">
            <slot name="start"></slot>
        </span>

        <slot name="default"></slot>

        <span
            v-if="slots.end"
            class="ml-3"
            :class="[
                'size-4 [&>*]:size-full',
                {
                    'fill-neutral-500': disabled,
                    'fill-neutral-1000': color.isPrimary || color.isSecondary || color.isInfo || color.isDanger || color.isWarning || color.isSuccess,
                    'fill-neutral-900': color.isWhite || color.isOutlinedLight || color.isOutlined,
                    'group-hover:fill-neutral-900 group-focus:fill-neutral-900': color.isSecondary || color.isTransparent,
                    'group-hover:fill-neutral-1000 group-focus:fill-neutral-1000':
                        color.isOutlined || color.isWhite || color.isInfo || color.isWarning || color.isSuccess || color.isDanger,
                    'fill-info-700': color.isInfo,
                    'fill-success-800': color.isSuccess,
                    'fill-warning-800': color.isWarning,
                    'fill-danger-800': color.isDanger,
                },
            ]">
            <slot name="end"></slot>
        </span>
    </component>
</template>
