<script setup>
import { computed, inject } from 'vue'

const trans = inject('trans')

const props = defineProps({
    inStock: {
        type: Boolean,
        required: true,
    },
    pilledShape: {
        type: Boolean,
    },
})

const getStock = computed(() =>
    props.inStock
        ? { text: trans.get('view.product.in-stock'), class: 'text-primary' }
        : { text: trans.get('view.product.out-of-stock'), class: 'text-danger-500' },
)
</script>

<template>
    <div class="flex">
        <span class="sr-only">Stock Availability</span>
        <p :class="[getStock.class, { 'rounded-full bg-white px-6 py-2': pilledShape }]" class="text-sm font-bold">
            {{ getStock.text }}
        </p>
    </div>
</template>
