<script setup>
import Button from '@/Components/Forms/Buttons/Button.vue'
import ProfileButton from '@/View/Layouts/Buttons/ProfileButton.vue'
import { usePage } from '@inertiajs/vue3'
import LogoutButton from '@/Components/Forms/Buttons/LogoutButton.vue'
import Navigation from '@/View/Layouts/Navigation/Navigation.vue'
import { computed, ref } from 'vue'
import { Dialog, DialogPanel } from '@/Composables/External/HeadlessUI/dialog'
import { Bars3Icon, XMarkIcon } from '@/Composables/External/HeroIcons/outline'
import Logo from '@/Components/Icons/Logo.vue'
import ShoppingCartButton from '../Buttons/ShoppingCartButton.vue'
import Container from '../Grid/Container.vue'

const mobileMenuOpen = ref(false)
const layoutElements = computed(() => usePage()?.props?.layoutElements)
const isLoggedIn = computed(() => usePage()?.props?.auth?.isLoggedIn)

const handleMenuToggle = (value = true) => {
    mobileMenuOpen.value = value
}
</script>

<template>
    <header class="bg-primary">
        <Container>
            <div class="mx-auto flex items-center justify-between py-4">
                <div class="flex lg:flex-1">
                    <a :href="$route('storefront')" class="-m-1.5 p-1.5">
                        <span class="sr-only">Denim Solar</span>
                        <Logo class="h-8 w-auto md:h-14" />
                    </a>
                </div>
                <div class="flex lg:hidden">
                    <button
                        class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
                        type="button"
                        @click="handleMenuToggle">
                        <span class="sr-only">Open main menu</span>
                        <Bars3Icon aria-hidden="true" class="size-6" />
                    </button>
                </div>
                <div class="hidden lg:block">
                    <Navigation
                        v-if="layoutElements?.mainNavigation"
                        :active-styling="true"
                        :navigation-items="layoutElements.mainNavigation"
                        link-classes="border-b-2 px-2 py-1 leading-6 text-white transition-all duration-200 hover:border-b-secondary"
                        list-classes="flex gap-8"
                        navigation-item-classes="inline-block">
                        <h2 id="primaryMenu" class="sr-only">Main navigation</h2>
                    </Navigation>
                </div>
                <div class="hidden items-center gap-6 lg:flex lg:flex-1 lg:justify-end">
                    <ShoppingCartButton />

                    <ProfileButton v-if="isLoggedIn" />

                    <LogoutButton v-if="isLoggedIn" color="white">{{ $trans.get('pages.auth.logout') }}</LogoutButton>
                    <Button v-if="!isLoggedIn" :url="$route('auth.login.form')" color="white">{{ $trans.get('pages.auth.login') }}</Button>
                </div>
            </div>
        </Container>
        <Dialog :open="mobileMenuOpen" as="div" class="lg:hidden" @close="handleMenuToggle(false)">
            <div class="fixed inset-0 z-10" />
            <DialogPanel class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-primary p-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
                <div class="flex items-center justify-between">
                    <a class="-m-1.5 p-1.5" href="#">
                        <span class="sr-only">Denim Solar</span>
                        <Logo class="h-8 w-auto" />
                    </a>
                    <button class="-m-2.5 rounded-md p-2.5 text-white" type="button" @click="handleMenuToggle(false)">
                        <span class="sr-only">Close menu</span>
                        <XMarkIcon aria-hidden="true" class="size-6" />
                    </button>
                </div>
                <div class="mt-6 flow-root">
                    <div class="-my-6 divide-y divide-white">
                        <div class="py-6">
                            <Navigation
                                v-if="layoutElements?.mainNavigation"
                                :active-styling="true"
                                :navigation-items="layoutElements.mainNavigation"
                                active-classes="bg-secondary !text-black"
                                link-classes="-mx-3 block rounded-lg px-3 py-2 text-base leading-7 text-white transition-all duration-200 hover:bg-secondary hover:text-black">
                                <h2 id="primaryMenu" class="sr-only">Main navigation</h2>
                            </Navigation>
                        </div>
                        <div class="flex flex-row items-center gap-6 py-6">
                            <ShoppingCartButton />

                            <ProfileButton v-if="isLoggedIn" />

                            <LogoutButton v-if="isLoggedIn" color="white">{{ $trans.get('pages.auth.logout') }}</LogoutButton>
                            <Button v-if="!isLoggedIn" :url="$route('auth.login.form')" color="white">{{ $trans.get('pages.auth.login') }}</Button>
                        </div>
                    </div>
                </div>
            </DialogPanel>
        </Dialog>
    </header>
</template>
