<script setup>
import Button from '@/Components/Forms/Buttons/Button.vue'
import IconFactory from '@/Components/Icons/IconFactory.vue'
import Row from '@/Components/Surfaces/Grid/Row.vue'
import { computed } from 'vue'

const props = defineProps({
    category: {
        type: String,
    },
    downloads: {
        type: Array,
    },
})

const needsFullWidth = computed(() => props.downloads.length > 5)
</script>

<template>
    <div :class="[needsFullWidth ? 'col-span-12' : 'col-span-12 md:col-span-6 lg:col-span-4']">
        <!--        <h3 class="mb-6 md:mb-12">{{ $trans.get(`documents.type.${category}`) }}</h3>-->
        <h3 class="mb-6 md:mb-12">{{ category }}</h3>

        <Row class="gap-y-2">
            <div
                :class="[needsFullWidth ? 'col-span-12 md:col-span-6 lg:col-span-4' : 'col-span-12']"
                v-for="download in downloads"
                :key="download.id">
                <Button type="a" color="primary" :url="download.file_url" :download="download.filename">
                    <template v-slot:start><IconFactory :icon="download.file_icon" /></template>
                    {{ download.title }}
                </Button>
            </div>
        </Row>
    </div>
</template>
