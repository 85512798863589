<script setup>
// ** Vue Imports
import { computed, inject, onMounted } from 'vue'

// ** Inertia Imports
import { useForm } from '@inertiajs/vue3'

// ** Components Imports
import FieldWrapper from '@/Components/Forms/Layouts/FieldWrapper.vue'
import InlineFormItem from '@/Components/Forms/Layouts/InlineFormItem.vue'
import InputForm from '@/Components/Forms/Input/Input.vue'
import Button from '@/Components/Forms/Buttons/Button.vue'
import Loading from '@/Components/Icons/Loading.vue'
import Select from '@/Components/Forms/Select/Select.vue'
import Send from '@/Components/Icons/Send.vue'
import { first, isEmpty, map, toLower } from 'lodash'

const route = inject('route')
const trans = inject('trans')

const props = defineProps({
    countries: {
        type: Array,
        required: true,
    },
})

const form = useForm({
    country: null,
    coc_number: null,
    vat_number: null,
    company_name: '',
    street: null,
    building_number: '',
    building_number_addition: '',
    postcode: '',
    city: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    remark: '',
})

const countryList = computed(() =>
    map(props.countries, (country) => {
        return {
            value: country.alpha_2,
            label: trans.get(toLower(`generic.country.${country.alpha_2}`)),
            requireOrganisationNumber: country.require_organisation_number,
        }
    }),
)

const organisationNumberRequired = computed(() => !isEmpty(form.country) && form.country.requireOrganisationNumber)

const hasCountry = computed(() => {
    return !isEmpty(form.country)
})

const onChangeCountry = ({ value }) => (form.country = value)

const submit = () => {
    form.transform((data) => ({
        ...data,
        country: data.country.value,
    })).post(route('account-request.store'))
}

const setInitialCountry = () => {
    const initialCountry = first(countryList.value)

    if (isEmpty(initialCountry)) {
        return
    }

    form.country = initialCountry
}

onMounted(() => {
    setInitialCountry()
})
</script>

<template>
    <form id="account-request-form" @submit.prevent="submit">
        <FieldWrapper :legend="$trans.get('page.account-request.legend.which-country-is-company')">
            <InlineFormItem id="country-field" :errors="form.errors.country" :label="$trans.get('page.account-request.form-label.country')" required>
                <Select :model-value="form.country" :on-change="onChangeCountry" :options="countryList" />
            </InlineFormItem>
        </FieldWrapper>

        <FieldWrapper :legend="$trans.get('page.account-request.legend.company-info')">
            <InlineFormItem
                id="coc-number-field"
                :errors="form.errors.coc_number"
                :label="$trans.get('page.account-request.form-label.coc_number')"
                :required="organisationNumberRequired">
                <InputForm
                    id="coc-number-input"
                    v-model="form.coc_number"
                    :errors="form.errors.coc_number"
                    class="w-full"
                    input-type="text"
                    :required="organisationNumberRequired"
                    @input="form.clearErrors()" />
            </InlineFormItem>
            <InlineFormItem
                id="vat-number-field"
                :errors="form.errors.vat_number"
                :label="$trans.get('page.account-request.form-label.vat_number')"
                required>
                <InputForm
                    id="vat-number-input"
                    v-model="form.vat_number"
                    :errors="form.errors.vat_number"
                    class="w-full"
                    input-type="text"
                    required
                    @input="form.clearErrors()" />
            </InlineFormItem>
            <InlineFormItem
                id="company-name-field"
                :errors="form.errors.company_name"
                :label="$trans.get('page.account-request.form-label.company_name')"
                required>
                <InputForm
                    id="company-name-input"
                    v-model="form.company_name"
                    :errors="form.errors.company_name"
                    class="w-full"
                    input-type="text"
                    required
                    @input="form.clearErrors()" />
            </InlineFormItem>
        </FieldWrapper>
        <FieldWrapper :legend="$trans.get('page.account-request.legend.address-info')">
            <InlineFormItem id="street-field" :errors="form.errors.street" :label="$trans.get('page.account-request.form-label.street')" required>
                <InputForm
                    id="street-input"
                    v-model="form.street"
                    :errors="form.errors.street"
                    class="w-full"
                    input-type="text"
                    required
                    @input="form.clearErrors()" />
            </InlineFormItem>
            <InlineFormItem
                id="building-number-field"
                :errors="form.errors.building_number"
                :label="$trans.get('page.account-request.form-label.building_number')"
                required>
                <InputForm
                    id="building-number-input"
                    v-model="form.building_number"
                    :errors="form.errors.building_number"
                    class="w-full"
                    input-type="number"
                    required
                    @input="form.clearErrors()" />

                <template v-slot:additionalField>
                    <InlineFormItem
                        id="building-number-addition-field"
                        :errors="form.errors.building_number_addition"
                        :label="$trans.get('page.account-request.form-label.building_number_addition')"
                        :width-classes="false"
                        class="mt-2 flex-col sm:mt-0 sm:flex-row">
                        <div class="flex justify-start sm:ml-2">
                            <InputForm
                                id="building-number-addition-input"
                                v-model="form.building_number_addition"
                                :disabled="form.processing"
                                :errors="form.errors.building_number_addition"
                                input-type="text"
                                @input="form.clearErrors()" />
                        </div>
                    </InlineFormItem>
                </template>
            </InlineFormItem>
            <InlineFormItem
                id="postcode-field"
                :errors="form.errors.postcode"
                :label="$trans.get('page.account-request.form-label.postcode')"
                required>
                <InputForm
                    id="postcode-input"
                    v-model="form.postcode"
                    :errors="form.errors.postcode"
                    class="w-full"
                    input-type="text"
                    required
                    @input="form.clearErrors()" />
            </InlineFormItem>
            <InlineFormItem id="city-field" :errors="form.errors.city" :label="$trans.get('page.account-request.form-label.city')" required>
                <InputForm
                    id="city-input"
                    v-model="form.city"
                    :errors="form.errors.city"
                    class="w-full"
                    input-type="text"
                    required
                    @input="form.clearErrors()" />
            </InlineFormItem>
        </FieldWrapper>
        <FieldWrapper :legend="$trans.get('page.account-request.legend.contact-info')">
            <InlineFormItem
                id="first-name-field"
                :errors="form.errors.first_name"
                :label="$trans.get('page.account-request.form-label.first_name')"
                required>
                <InputForm
                    id="first-name-input"
                    v-model="form.first_name"
                    :errors="form.errors.first_name"
                    class="w-full"
                    input-type="text"
                    required
                    @input="form.clearErrors()" />
            </InlineFormItem>
            <InlineFormItem
                id="last-name-field"
                :errors="form.errors.last_name"
                :label="$trans.get('page.account-request.form-label.last_name')"
                required>
                <InputForm
                    id="last-name-input"
                    v-model="form.last_name"
                    :errors="form.errors.last_name"
                    class="w-full"
                    input-type="text"
                    required
                    @input="form.clearErrors()" />
            </InlineFormItem>
            <InlineFormItem id="email-field" :errors="form.errors.email" :label="$trans.get('page.account-request.form-label.email')" required>
                <InputForm
                    id="email-input"
                    v-model="form.email"
                    :errors="form.errors.email"
                    class="w-full"
                    input-type="text"
                    required
                    @input="form.clearErrors()" />
            </InlineFormItem>
            <InlineFormItem id="phone-field" :errors="form.errors.phone" :label="$trans.get('page.account-request.form-label.phone')" required>
                <InputForm
                    id="phone-input"
                    v-model="form.phone"
                    :errors="form.errors.phone"
                    class="w-full"
                    input-type="text"
                    required
                    @input="form.clearErrors()" />
            </InlineFormItem>
        </FieldWrapper>
        <FieldWrapper :legend="$trans.get('page.account-request.legend.notes')">
            <textarea
                id="remark-input"
                v-model="form.remark"
                :placeholder="$trans.get('page.account-request.form-label.remark_placeholder')"
                class="w-full rounded border-0 bg-accent text-sm"
                rows="5"
                @input="form.clearErrors()" />
        </FieldWrapper>
        <Button
            :class="{ 'opacity-50 hover:bg-primary': form.processing }"
            :disabled="form.processing || form.hasErrors || !hasCountry"
            class="mb-8"
            color="primary"
            full-width="md"
            type="submit"
            @keydown="form.clearErrors()">
            <template v-slot:start>
                <Loading v-if="form.processing" />
                <Send v-else />
            </template>
            {{ $trans.get('page.account-request.form-label.submit') }}
        </Button>
    </form>
</template>
