<script setup>
import Alert from '@/Components/Feedback/Alerts/Alert.vue'
import Checkbox from '@/Components/Forms/Checkbox/Checkbox.vue'
import Row from '@/Components/Surfaces/Grid/Row.vue'
import moment from '@/Composables/External/moment.js'
import OrderDetails from '@/View/Checkout/Form/OrderDetails.vue'
import axios from 'axios'
import { isEmpty, isNil, map, toLower } from 'lodash'
import { computed, inject, onMounted, ref, watch } from 'vue'
import { useForm, usePage } from '@inertiajs/vue3'
import Send from '@/Components/Icons/Send.vue'
import Select from '@/Components/Forms/Select/Select.vue'
import Input from '@/Components/Forms/Input/Input.vue'
import Panel from '@/Components/Surfaces/Card/Panel.vue'
import Button from '@/Components/Forms/Buttons/Button.vue'
import Loading from '@/Components/Icons/Loading.vue'
import FieldWrapper from '@/Components/Forms/Layouts/FieldWrapper.vue'
import InlineFormItem from '@/Components/Forms/Layouts/InlineFormItem.vue'

import { VueDatePicker } from '@/Composables/External/vueDatepicker.js'

const props = defineProps({
    countries: {
        type: Array,
        required: true,
    },
    cart: {
        type: Object,
        required: true,
    },
})

const trans = inject('trans')
const route = inject('route')

const form = useForm({
    country: null,
    street: '',
    building_number: '',
    building_number_addition: '',
    postcode: '',
    city: '',
    reference: '',
    delivery_date: '',
})

const companyName = ref('')
const firstName = ref('')
const lastName = ref('')
const paymentMethod = ref('')
const transportFee = ref(null)
const loading = ref(false)
const hasTransportCostError = ref(false)
const user = usePage()?.props?.auth?.user
const firstDeliverableDate = ref(moment().add(1, 'days').toDate())
const termsAndConditions = ref(false)

const countryList = computed(() =>
    map(props.countries, (country) => {
        return {
            value: country.alpha_2,
            label: trans.get(toLower(`generic.country.${country.alpha_2}`)),
        }
    }),
)

const deliveryDatePickerEnabled = computed(() => {
    return !isEmpty(form.country) && !isEmpty(form.postcode) && form.postcode.length > 3
})

watch(
    () => form.country,
    () => resetShippingAddress(),
    { deep: true },
)

watch(
    () => [form.country, form.postcode],
    () => {
        if (!deliveryDatePickerEnabled.value) {
            return
        }

        fetchTransportCost()
    },
    { deep: true },
)

const resetShippingAddress = () => {
    form.street = ''
    form.building_number = ''
    form.building_number_addition = ''
    form.postcode = ''
    form.city = ''
    form.delivery_date = ''
}

const onSubmit = () => {
    form.transform((data) => ({
        ...data,
        country: data.country.value,
    })).post(route('checkout.store'))
}

const fetchTransportCost = () => {
    if (isEmpty(form.postcode) || isNil(form.country.value)) {
        return
    }

    loading.value = true

    axios
        .get(route('transport.calculate'), {
            params: {
                country: form.country.value,
                postcode: form.postcode,
            },
        })
        .then((response) => {
            hasTransportCostError.value = false
            const deliveryDate = moment(response.data?.firstDeliverableDate)

            transportFee.value = response.data?.transportFee
            firstDeliverableDate.value = deliveryDate.toDate()
            form.delivery_date = deliveryDate.format('yyyy-MM-DD')
        })
        .catch((error) => {
            hasTransportCostError.value = true
            transportFee.value = null
            firstDeliverableDate.value = moment().add(1, 'days').toDate()
            form.errors = error?.response?.data?.errors ?? {}
        })
        .finally(() => (loading.value = false))
}

onMounted(() => {
    companyName.value = user.salesRelation.name
    firstName.value = user.firstName
    lastName.value = user.lastName
    paymentMethod.value = user.salesRelation.paymentMethod
})
</script>

<template>
    <form id="checkout-form" @submit.prevent="onSubmit">
        <Row>
            <div class="col-span-full lg:col-span-6">
                <div aria-label="Checkout Form">
                    <FieldWrapper :legend="$trans.get('page.checkout.legend.information')">
                        <InlineFormItem :label="$trans.get('page.checkout.form-label.company_name')">
                            <Input id="company-name-input" v-model="companyName" class="w-full" input-type="text" readonly />
                        </InlineFormItem>
                        <InlineFormItem :label="$trans.get('page.checkout.form-label.first_name')">
                            <Input id="first-name-input" v-model="firstName" class="w-full" input-type="text" readonly />
                        </InlineFormItem>
                        <InlineFormItem :label="$trans.get('page.checkout.form-label.last_name')">
                            <Input id="last-name-input" v-model="lastName" class="w-full" input-type="text" readonly />
                        </InlineFormItem>
                    </FieldWrapper>

                    <FieldWrapper :legend="$trans.get('page.checkout.legend.payment-information')">
                        <InlineFormItem :label="$trans.get('page.checkout.form-label.payment-method')">
                            <Alert type="info">
                                <p>
                                    <strong class="block">{{ $trans.get(`page.checkout.payment-option.title.${paymentMethod}`) }}</strong>
                                    {{ $trans.get(`page.checkout.payment-option.description.${paymentMethod}`) }}
                                </p>
                            </Alert>
                        </InlineFormItem>
                        <InlineFormItem
                            id="reference-field"
                            :errors="form.errors?.reference"
                            :label="$trans.get('page.checkout.form-label.reference')">
                            <Input
                                id="reference-input"
                                v-model="form.reference"
                                :errors="form.errors?.reference"
                                class="w-full"
                                input-type="text"
                                @input="form.clearErrors()" />
                        </InlineFormItem>
                    </FieldWrapper>

                    <FieldWrapper :legend="$trans.get('page.checkout.legend.shipping-address')">
                        <InlineFormItem
                            id="country-field"
                            :errors="form.errors?.country"
                            :label="$trans.get('page.checkout.form-label.country')"
                            required>
                            <Select v-model="form.country" :options="countryList" />
                        </InlineFormItem>
                        <InlineFormItem
                            id="street-field"
                            :errors="form.errors?.street"
                            :label="$trans.get('page.checkout.form-label.street')"
                            required>
                            <Input
                                id="street-input"
                                v-model="form.street"
                                :errors="form.errors?.street"
                                class="w-full"
                                input-type="text"
                                required
                                @input="form.clearErrors()" />
                        </InlineFormItem>
                        <InlineFormItem
                            id="building-number-field"
                            :errors="form.errors?.building_number"
                            :label="$trans.get('page.checkout.form-label.building_number')"
                            required>
                            <Input
                                id="building-number-input"
                                v-model="form.building_number"
                                :errors="form.errors?.building_number"
                                class="w-full"
                                input-type="number"
                                required
                                @input="form.clearErrors()" />

                            <template v-slot:additionalField>
                                <InlineFormItem
                                    id="building-number-addition-field"
                                    :errors="form.errors?.building_number_addition"
                                    :label="$trans.get('page.checkout.form-label.building_number_addition')"
                                    :width-classes="false"
                                    class="mt-2 flex-col sm:mt-0 sm:flex-row">
                                    <div class="flex justify-start sm:ml-2">
                                        <Input
                                            id="building-number-addition-input"
                                            v-model="form.building_number_addition"
                                            :disabled="form.processing"
                                            :errors="form.errors?.building_number_addition"
                                            input-type="text"
                                            @input="form.clearErrors()" />
                                    </div>
                                </InlineFormItem>
                            </template>
                        </InlineFormItem>
                        <InlineFormItem
                            id="postcode-field"
                            :errors="form.errors?.postcode"
                            :label="$trans.get('page.checkout.form-label.postcode')"
                            required>
                            <Input
                                id="postcode-input"
                                v-model="form.postcode"
                                :errors="form.errors?.postcode"
                                class="w-full"
                                input-type="text"
                                required
                                @input="form.clearErrors()" />
                        </InlineFormItem>
                        <InlineFormItem id="city-field" :errors="form.errors?.city" :label="$trans.get('page.checkout.form-label.city')" required>
                            <Input
                                id="city-input"
                                v-model="form.city"
                                :errors="form.errors?.city"
                                class="w-full"
                                input-type="text"
                                required
                                @input="form.clearErrors()" />
                        </InlineFormItem>
                    </FieldWrapper>

                    <FieldWrapper :errors="form.errors?.delivery_date" :legend="$trans.get('page.checkout.legend.delivery-date')" required>
                        <Alert v-if="!deliveryDatePickerEnabled" type="warning" class="mb-6">
                            {{ $trans.get('page.checkout.warning.can-not-pick-delivery-date') }}
                        </Alert>
                        <Alert v-if="hasTransportCostError" type="warning" class="mb-6">
                            {{ $trans.get('page.checkout.warning.can-not-fetch-transport-cost') }}
                        </Alert>
                        <Panel aria-label="Delivery date picker" class="relative p-1 sm:p-6 md:p-12">
                            <VueDatePicker
                                key="delivery_date-input"
                                v-model="form.delivery_date"
                                :auto-apply="true"
                                :disabled="!deliveryDatePickerEnabled || hasTransportCostError"
                                :disabled-week-days="[0, 6]"
                                :enable-time-picker="false"
                                :hide-offset-dates="true"
                                :inline="true"
                                :min-date="firstDeliverableDate"
                                :month-change-on-scroll="false"
                                :prevent-min-max-navigation="true"
                                model-type="yyyy-MM-dd"
                                week-numbers="iso" />

                            <Transition name="fade">
                                <div v-if="loading" class="absolute inset-0 z-[999999] flex items-center justify-center rounded-3xl bg-gray-50/50">
                                    <Loading class="size-10 text-primary" />
                                </div>
                            </Transition>
                        </Panel>
                    </FieldWrapper>
                </div>
            </div>
            <div class="col-span-full mt-4 md:mt-12 lg:col-span-6 lg:mt-0 xl:col-span-5 xl:col-start-8">
                <OrderDetails
                    :delivery-date="form.delivery_date"
                    :grand-total="cart.grandTotal"
                    :items="cart.items"
                    :subtotal="cart.subtotal"
                    :transport-fee="transportFee">
                    <div class="mt-6">
                        <Checkbox
                            v-model="termsAndConditions"
                            id="checkbox-checkout-terms-and-conditions"
                            name="checkbox-checkout-terms-and-conditions"
                            required="required">
                            <div
                                class="[&>a:hover]:no-underline [&>a]:underline"
                                v-html="$trans.get('page.checkout.form-label.checkbox.terms-and-conditions')"></div>
                        </Checkbox>
                    </div>

                    <div class="mt-6">
                        <Button
                            :class="{ 'opacity-50 hover:bg-primary': form.processing }"
                            :disabled="
                                form.processing || form.hasErrors || hasTransportCostError || !deliveryDatePickerEnabled || !termsAndConditions
                            "
                            color="primary"
                            full-width="all"
                            type="submit"
                            @keydown="form.clearErrors()">
                            <template v-slot:start>
                                <Loading v-if="form.processing" />
                                <Send v-else />
                            </template>
                            {{ $trans.get('page.checkout.form-label.confirm-order') }}
                        </Button>
                    </div>
                </OrderDetails>
            </div>
        </Row>
    </form>
</template>
