<script setup>
import Button from '@/Components/Forms/Buttons/Button.vue'
import Input from '@/Components/Forms/Input/Input.vue'
import InlineFormItem from '@/Components/Forms/Layouts/InlineFormItem.vue'
import Email from '@/Components/Icons/Email.vue'
import Phone from '@/Components/Icons/Phone.vue'
import BannerImage from '@/Components/Images/BannerImage.vue'
import Panel from '@/Components/Surfaces/Card/Panel.vue'
import Container from '@/Components/Surfaces/Grid/Container.vue'
import Row from '@/Components/Surfaces/Grid/Row.vue'
import MasterLayout from '@/View/Layouts/MasterLayout.vue'
import { Head, useForm, router } from '@inertiajs/vue3'
import { inject } from 'vue'

defineProps({
    pageTitle: {
        type: String,
    },
    subtitle: {
        type: String,
    },
    metaDescription: {
        type: String,
    },
    body: {
        type: String,
    },
    bannerImageUrl: {
        type: String,
    },
    aboutUsText: {
        type: String,
    },
    uspsText: {
        type: String,
    },
    contactInfoText: {
        type: String,
    },
    phoneNumber: {
        type: String,
    },
    emailAddress: {
        type: String,
    },
})

const route = inject('route')

const form = useForm({
    email: null,
    password: null,
})

const handleForgotPassword = () => {
    router.visit(route('auth.forgot-password.form'))
}
</script>

<template>
    <MasterLayout wrapper-class="md:mt-24 mb-8">
        <Head>
            <title>{{ pageTitle }}</title>
            <meta v-if="metaDescription" name="description" :content="metaDescription" />
        </Head>

        <Container>
            <Row>
                <div class="col-span-full">
                    <h1>{{ pageTitle }}</h1>
                    <p class="md:text-2xl">{{ subtitle }}</p>
                </div>
            </Row>

            <Row class="mt-10 md:mt-20">
                <div class="col-span-full lg:col-span-6">
                    <Panel>
                        <form @submit.prevent="form.post($route('auth.login.submit'))" class="flex flex-col">
                            <InlineFormItem id="email" :label="$trans.get('pages.auth.login.form.fields.email')" :errors="form.errors?.email">
                                <Input id="email" input-type="email" v-model="form.email" />
                            </InlineFormItem>

                            <InlineFormItem
                                id="password"
                                :label="$trans.get('pages.auth.login.form.fields.password')"
                                :errors="form.errors?.password">
                                <Input id="password" input-type="password" v-model="form.password" />
                            </InlineFormItem>

                            <div class="ml-auto mt-2 w-full md:w-2/3">
                                <Button class="block w-full" type="submit" :disabled="form.processing">
                                    {{ $trans.get('pages.auth.login') }}
                                </Button>

                                <button type="button" id="forgot-password" @click.prevent="handleForgotPassword" class="mt-2 hocus:underline">
                                    {{ $trans.get('pages.auth.forgot-password') }}
                                </button>
                            </div>
                        </form>
                    </Panel>
                </div>
                <div class="col-span-full mt-8 md:mt-12 lg:col-span-6 lg:mt-0 xl:col-span-5 xl:col-start-8">
                    <div v-html="body" class="primary-dots prose"></div>

                    <Button :url="$route('account-request.form')">{{ $trans.get('pages.auth.register') }}</Button>
                </div>
            </Row>

            <Row class="mt-8 md:mt-12">
                <div class="col-span-full">
                    <div class="grid gap-8 xl:grid-cols-3">
                        <div class="space-y-4">
                            <div class="font-light" v-html="aboutUsText" />
                        </div>

                        <div class="primary-dots prose font-light text-black" v-html="uspsText"></div>

                        <div class="space-y-4">
                            <div class="font-light" v-html="contactInfoText" />

                            <div class="grid gap-4">
                                <a :href="`tel:${phoneNumber}`" class="flex items-center gap-4 font-bold no-underline hocus:underline">
                                    <Phone class="size-5" />
                                    {{ phoneNumber }}
                                </a>

                                <a :href="`mailto:${emailAddress}`" class="flex items-center gap-4 font-bold no-underline hocus:underline">
                                    <Email class="size-5" />
                                    {{ emailAddress }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-span-full mt-8 md:mt-12">
                    <BannerImage :image="bannerImageUrl" :title="pageTitle" />
                </div>
            </Row>
        </Container>
    </MasterLayout>
</template>
