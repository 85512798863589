<script setup>
import Row from '@/Components/Surfaces/Grid/Row.vue'
import usePriceFormatter from '@/Composables/usePriceFormatter.js'

const priceFormatter = usePriceFormatter()

defineProps({
    products: {
        type: Array,
        required: true,
    },
})
</script>

<template>
    <dl class="space-y-2">
        <Row v-for="item in products" :key="item.id" class="rounded-lg bg-white p-2">
            <div class="col-span-full flex gap-3 md:col-span-8">
                <img :src="item.product.thumbnail" :alt="item.title" class="aspect-square size-12 object-contain object-center" />
                <div class="flex flex-col">
                    <dt class="line-clamp-1 font-semibold">{{ item.title }}</dt>
                    <dt class="line-clamp-2 text-sm text-neutral-500">{{ item.product.subtitle }}</dt>
                </div>
            </div>
            <dd class="col-span-2 self-center pl-4 md:col-span-1 md:p-0">
                {{ item.quantity }}
            </dd>
            <dd class="col-span-2 self-center justify-self-end md:col-span-3">
                {{ priceFormatter.format(item.total) }}
            </dd>
        </Row>
    </dl>
</template>
