<script setup>
import { twMerge } from '@/Composables/External/twMerge.js'
import { computed } from 'vue'

const props = defineProps({
    class: {
        type: String,
    },
})

const classes = computed(() => twMerge('rounded-3xl border-2 border-accent p-6 md:p-12', props.class))
</script>
<template>
    <div :class="classes">
        <slot></slot>
    </div>
</template>
