<script setup>
import { twMerge } from '@/Composables/External/twMerge.js'
import { Link } from '@inertiajs/vue3'
import { computed } from 'vue'

const props = defineProps({
    navigationItem: {
        type: Object,
    },
    linkClasses: {
        type: String,
    },
    class: {
        type: String,
    },
})

const classes = computed(() => twMerge('py-1 md:py-2', props.class))

const isExternal = computed(() => {
    const value = props.navigationItem.value

    return !(value && (!value.includes('https://') || value.includes('denimsolar')))
})

const getType = computed(() => {
    if (!props.navigationItem.value) return 'span'
    if (props.navigationItem.target === '_blank' || isExternal.value) return 'a'
    if (!isExternal.value) return Link

    return 'button'
})
</script>

<template>
    <li :class="classes">
        <component :is="getType" :href="navigationItem.value" :target="navigationItem?.target" :class="twMerge('hocus:no-underline', linkClasses)">
            {{ navigationItem?.name }}
        </component>
    </li>
</template>
