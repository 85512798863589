<script setup>
import { computed } from 'vue'
import { twMerge } from '@/Composables/External/twMerge.js'

const props = defineProps({
    status: {
        type: [String, null],
        required: true,
    },
    statusList: {
        type: Object,
        required: true,
        class: {
            type: String,
        },
        name: {
            type: String,
        },
    },
    class: {
        type: String,
    },
})

const getStatus = computed(() => {
    return props.statusList[props.status]
})

const classNames = computed(() => {
    return twMerge(['rounded-full border-0 py-1 px-3 text-center text-xs font-light whitespace-nowrap', props.class, getStatus.value?.class])
})
</script>

<template>
    <div v-if="getStatus" :class="classNames">
        {{ getStatus?.name }}
    </div>
</template>
