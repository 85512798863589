<script setup>
import { twMerge } from '@/Composables/External/twMerge'
import { computed } from 'vue'

const props = defineProps({
    accessibleName: {
        type: String,
    },
    class: {
        type: String,
        default: '',
    },
})

const classNames = computed(() => {
    return twMerge(
        'transition--hover flex h-11 w-11 shrink-0 items-center justify-center rounded-full bg-white p-2 no-underline group-hover:bg-text group-hover:fill-accent ',
        props.class,
    )
})
</script>

<template>
    <span :aria-label="accessibleName" :class="classNames">
        <slot class="w-full"></slot>
    </span>
</template>
