<script setup>
import IconFactory from '@/Components/Icons/IconFactory.vue'
import Container from '@/Components/Surfaces/Grid/Container.vue'
import Row from '@/Components/Surfaces/Grid/Row.vue'
import { usePage } from '@inertiajs/vue3'
import { isNil } from 'lodash'
import { computed } from 'vue'

const footerData = computed(() => usePage()?.props?.layoutElements?.footer)

const footerColumns = [
    {
        title: 'footer_title_column_one',
        text: 'footer_text_column_one',
    },
    {
        title: 'footer_title_column_two',
        text: 'footer_text_column_two',
    },
    {
        title: 'footer_title_column_three',
        text: 'footer_text_column_three',
    },
    {
        title: 'footer_title_column_four',
        text: 'footer_text_column_four',
    },
]

const socialMapping = [
    {
        key: 'social_facebook',
        name: 'Facebook',
        icon: 'Facebook',
    },
    {
        key: 'social_instagram',
        name: 'Instagram',
        icon: 'Instagram',
    },
    {
        key: 'social_linkedin',
        name: 'LinkedIn',
        icon: 'LinkedIn',
    },
    {
        key: 'social_twitter',
        name: 'Twitter',
        icon: 'Twitter',
    },
]

const hasSocials = computed(() => socialMapping.some((item) => !isNil(footerData.value[item.key])))
</script>

<template>
    <div class="bg-primary pb-8 pt-12 text-white md:pt-20 lg:pt-24">
        <Container>
            <Row class="gap-4 md:gap-8">
                <div class="col-span-full md:col-span-12 lg:col-span-9">
                    <Row class="gap-4 md:gap-8">
                        <div class="col-span-full md:col-span-6 lg:col-span-3" v-for="(column, index) in footerColumns" :key="index">
                            <h4>{{ footerData[column.title] }}</h4>

                            <div v-html="footerData[column.text]"></div>
                        </div>
                    </Row>
                </div>

                <div class="col-span-full md:col-span-12 lg:col-span-3 lg:text-right">
                    <h4>{{ footerData.footer_title_column_five }}</h4>

                    <div v-html="footerData.footer_text_column_five"></div>

                    <ul v-if="hasSocials" class="mt-12 flex items-center gap-4 lg:justify-end">
                        <li v-for="(social, index) in socialMapping" :key="index" class="text-gray-500 hover:text-white">
                            <a :href="footerData[social.key]" key="social.key">
                                <IconFactory class="transition-all duration-200" :icon="social.icon" />
                                <span class="sr-only">{{ social.name }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </Row>

            <Row class="mt-8 gap-4 border-t border-t-white pt-8 md:mt-12 md:gap-8">
                <div class="col-span-4 text-center md:col-span-6 md:text-left">
                    <span>{{ footerData.footer_copyright }}</span>
                </div>
            </Row>
        </Container>
    </div>
</template>
