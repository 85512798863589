<script setup>
import { isArray } from 'lodash'
import { computed, inject } from 'vue'

const trans = inject('trans')

const props = defineProps({
    label: {
        type: String,
        default: '',
        required: true,
    },
    subLabel: {
        type: String,
        default: '',
        required: false,
    },
    widthClasses: {
        type: Boolean,
        default: true,
    },
    errors: {
        type: [String, Object],
        required: false,
    },
    required: {
        type: Boolean,
        required: false,
        default: false,
    },
})

const errorMessage = computed(() => {
    const { errors } = props

    return isArray(errors) ? errors.map((key) => trans.get(key, { attribute: '' })).join(', ') : errors
})
</script>

<template>
    <div :class="{ flex: !widthClasses }" class="mb-2 last:mb-0 md:flex">
        <div :class="{ 'md:w-1/3': widthClasses }">
            <span class="mb-0 font-light md:pt-2" :class="{ required: required }">{{ label }}</span>
            <div>
                <span class="mb-0 text-sm font-light opacity-60 md:pt-2">{{ subLabel }}</span>
            </div>
        </div>

        <div :class="{ 'md:w-2/3': widthClasses }" class="w-full">
            <div
                :class="{
                    'grid grid-flow-row gap-x-2 sm:grid-flow-col': $slots.additionalField,
                }"
                class="py-3 md:p-0">
                <slot></slot>
                <slot name="additionalField"></slot>
            </div>

            <div v-if="errors" class="text-sm text-danger">
                <p class="mb-0" data-testid="error-message">{{ errorMessage }}</p>
            </div>
        </div>
    </div>
</template>
