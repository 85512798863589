<script setup>
import { computed } from 'vue'
import { isArray } from 'lodash'

const props = defineProps({
    legend: {
        type: String,
        required: false,
    },
    errors: {
        type: [Array, String],
        required: false,
    },
    required: {
        type: Boolean,
        required: false,
    },
})

const errorMessage = computed(() => {
    const { errors } = props

    return isArray(errors) ? errors.map((key) => key).join(', ') : errors
})
</script>

<template>
    <fieldset class="mb-8 last:mb-0 lg:mb-12">
        <legend v-if="legend" class="mb-6 font-semibold" :class="{ required: required }">{{ legend }}</legend>
        <slot></slot>

        <div v-if="errors" class="text-sm text-danger">
            <span data-testid="error-message">{{ errorMessage }}</span>
        </div>
    </fieldset>
</template>
