<script setup>
import { Link } from '@inertiajs/vue3'
import { computed } from 'vue'

const props = defineProps({
    url: {
        type: [String],
        required: false,
    },
})

const getType = computed(() => {
    if (props.url) {
        return Link
    }

    return 'span'
})
</script>
<template>
    <component :is="getType" :href="url" :preserve-scroll="false" :class="[{ 'cursor-pointer hocus:underline': getType !== 'span' }, 'no-underline']">
        <slot></slot>
    </component>
</template>
