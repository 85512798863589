<script setup>
import Button from '@/Components/Forms/Buttons/Button.vue'
import Input from '@/Components/Forms/Input/Input.vue'
import InlineFormItem from '@/Components/Forms/Layouts/InlineFormItem.vue'
import Eye from '@/Components/Icons/Eye.vue'
import EyeOff from '@/Components/Icons/EyeOff.vue'
import ListChecker from '@/View/Auth/ListChecker.vue'
import { useForm } from '@inertiajs/vue3'
import { isEmpty } from 'lodash'
import { computed, inject, ref } from 'vue'

const trans = inject('trans')
const route = inject('route')
const showNewPassword = ref(false)
const showNewPasswordConfirmation = ref(false)

const form = useForm({
    current_password: '',
    new_password: '',
    new_password_confirmation: '',
})

const isLongEnough = computed(() => {
    return form.new_password.length >= 8
})

const hasCapitalLetter = computed(() => {
    return /[A-Z]/.test(form.new_password)
})

const hasLowercaseLetter = computed(() => {
    return /[a-z]/.test(form.new_password)
})

const hasNumber = computed(() => {
    return /[0-9]/.test(form.new_password)
})

const hasSpecialCharacter = computed(() => {
    return /[!@#$%^&*(),.?":{}|<>]/.test(form.new_password)
})

const passwordMatched = computed(() => {
    return !isEmpty(form.new_password) && !isEmpty(form.new_password_confirmation) && form.new_password === form.new_password_confirmation
})

const isValidPassword = computed(() => {
    return (
        isLongEnough.value &&
        hasCapitalLetter.value &&
        hasLowercaseLetter.value &&
        hasNumber.value &&
        hasSpecialCharacter.value &&
        passwordMatched.value
    )
})

const PasswordRules = computed(() => {
    return [
        { condition: isLongEnough.value, label: trans.get('page.my-account.profile.change-password.form-rule.least-character') },
        { condition: hasCapitalLetter.value, label: trans.get('page.my-account.profile.change-password.form-rule.capital') },
        { condition: hasLowercaseLetter.value, label: trans.get('page.my-account.profile.change-password.form-rule.lowercase') },
        { condition: hasNumber.value, label: trans.get('page.my-account.profile.change-password.form-rule.number') },
        { condition: hasSpecialCharacter.value, label: trans.get('page.my-account.profile.change-password.form-rule.special') },
        { condition: passwordMatched.value, label: trans.get('page.my-account.profile.change-password.form-rule.match') },
    ]
})

const onSubmit = () => {
    form.post(route('change-password'))
    form.reset()
}
</script>

<template>
    <form @submit.prevent="onSubmit" id="change-password-form">
        <InlineFormItem
            :label="$trans.get('page.my-account.profile.change-password.form.label.current-password')"
            id="current-password"
            :errors="form.errors.current_password"
            required>
            <Input
                input-type="password"
                id="current-password-input"
                class="w-full"
                v-model="form.current_password"
                @input="form.clearErrors()"
                required
                :errors="form.errors.current_password" />
        </InlineFormItem>

        <InlineFormItem
            :label="$trans.get('page.my-account.profile.change-password.form.label.new-password')"
            id="new-password"
            :errors="form.errors.new_password"
            required>
            <div class="relative">
                <Input
                    :input-type="showNewPassword ? 'text' : 'password'"
                    id="new-password-input"
                    class="w-full pr-10"
                    v-model="form.new_password"
                    @input="form.clearErrors()"
                    required
                    :errors="form.errors.new_password" />

                <div class="absolute right-3 top-2">
                    <Eye v-if="!showNewPassword" class="size-5 cursor-pointer" @click="showNewPassword = true" />
                    <EyeOff v-else class="size-5 cursor-pointer" @click="showNewPassword = false" />
                </div>
            </div>

            <ListChecker class="mt-4" :rules="PasswordRules" />
        </InlineFormItem>

        <InlineFormItem
            :label="$trans.get('page.my-account.profile.change-password.form.label.new-password-confirmed')"
            class="mt-4"
            id="new-password-confirm"
            :errors="form.errors.new_password_confirmation"
            required>
            <div class="relative">
                <Input
                    :input-type="showNewPasswordConfirmation ? 'text' : 'password'"
                    id="new-password-confirm-input"
                    class="w-full pr-10"
                    v-model="form.new_password_confirmation"
                    @input="form.clearErrors()"
                    required
                    :errors="form.errors.new_password_confirmation" />

                <div class="absolute right-3 top-2">
                    <Eye v-if="!showNewPasswordConfirmation" class="size-5 cursor-pointer" @click="showNewPasswordConfirmation = true" />
                    <EyeOff v-else class="size-5 cursor-pointer" @click="showNewPasswordConfirmation = false" />
                </div>
            </div>
        </InlineFormItem>

        <Button type="submit" class="ml-auto mt-4 block" :disabled="form.processing || form.hasErrors || !isValidPassword">
            {{ $trans.get('page.my-account.profile.change-password.form.submit') }}
        </Button>
    </form>
</template>
