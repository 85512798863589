<script setup>
import { twMerge } from '@/Composables/External/twMerge.js'
import NavigationItem from '@/View/Layouts/Navigation/NavigationItem.vue'
import { computed } from 'vue'

const props = defineProps({
    navigationItems: {
        type: Array,
        required: true,
    },
    class: {
        type: String,
    },
    listClasses: {
        type: String,
    },
    linkClasses: {
        type: String,
    },
    navigationItemClasses: {
        type: String,
    },
    activeStyling: {
        type: Boolean,
        default: false,
    },
    activeClasses: {
        type: String,
        default: 'border-b-secondary',
    },
})

const classes = computed(() => twMerge('', props.class))

const checkIsActive = (path) => {
    if (!props.activeStyling) {
        return ''
    }

    return window.location.pathname === path ? props.activeClasses : 'border-b-transparent'
}
</script>

<template>
    <nav :class="classes">
        <slot></slot>
        <ul role="list" :class="listClasses">
            <NavigationItem
                v-for="item in navigationItems"
                :class="navigationItemClasses"
                :link-classes="twMerge(linkClasses, checkIsActive(item.value))"
                :key="item.id"
                :navigation-item="item" />
        </ul>
    </nav>
</template>
