<script setup>
const beforeEnter = (el) => {
    el.style.height = '0px'
    el.style.opacity = 0
}

const enter = (el) => {
    el.style.height = el.scrollHeight + 'px'
}

const afterEnter = (el) => {
    el.style.height = 'auto'
    el.style.opacity = 1
}

const beforeLeave = (el) => {
    el.style.height = el.scrollHeight + 'px'
}

const leave = (el) => {
    el.style.height = '0'
}
</script>

<template>
    <Transition v-on:before-enter="beforeEnter" v-on:after-enter="afterEnter" v-on:enter="enter" v-on:before-leave="beforeLeave" v-on:leave="leave">
        <slot></slot>
    </Transition>
</template>

<style scoped>
* {
    transition:
        opacity 0.2s ease-in-out,
        height 0.2s ease-in-out 0.5s;
}

.v-enter {
    opacity: 1;
}

.v-enter-to,
.v-leave-to {
    opacity: 0;
}
</style>
