import { defineStore } from 'pinia'

export const useCartStore = defineStore('cart', {
    state: () => ({
        cart: {},
    }),

    getters: {
        getTotalQuantity() {
            return this.cart?.itemCount ?? 0
        },
    },

    actions: {
        setCart(cart) {
            this.cart = cart
        },
    },
})
