<script setup>
import Container from '@/Components/Surfaces/Grid/Container.vue'
import Row from '@/Components/Surfaces/Grid/Row.vue'
import Hero from '@/View/Cms/Content/Hero.vue'
import MasterLayout from '@/View/Layouts/MasterLayout.vue'
import { Head } from '@inertiajs/vue3'
import { isEmpty } from 'lodash'
import { computed } from 'vue'

const props = defineProps({
    pageTitle: {
        type: String,
        required: true,
    },
    metaDescription: {
        type: String,
        required: true,
    },
    body: {
        type: String,
        required: true,
    },
    slug: {
        type: String,
    },
    hero: {
        type: [Object, Array],
    },
})

const hasHero = computed(() => !isEmpty(props.hero))
</script>

<template>
    <Head>
        <title>{{ pageTitle }}</title>
        <meta :content="metaDescription" name="description" />
    </Head>
    <MasterLayout>
        <Container>
            <Row>
                <div class="col-span-full" :class="{ 'text-primary': !hasHero }">
                    <Hero
                        v-if="hasHero"
                        :title="hero.title"
                        :body="hero.body"
                        :image="hero.image_url"
                        :subtitle="hero.subtitle"
                        :button-text="hero.button_text"
                        :button-url="hero.button_url"
                        :title-as-heading="true" />
                    <h1 v-if="!hasHero">{{ pageTitle }}</h1>
                </div>
            </Row>

            <Row class="my-14 md:my-16">
                <div
                    class="col-span-full md:col-span-10 lg:col-span-8"
                    :class="{
                        'md:col-start-2 lg:col-start-3': hasHero,
                    }">
                    <div v-html="body"></div>
                </div>
            </Row>
        </Container>
    </MasterLayout>
</template>
