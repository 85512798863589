<script setup>
import FaqWrapper from '@/Components/Faq/FaqWrapper.vue'
import HubspotForm from '@/Components/Forms/HubspotForm.vue'
import Card from '@/Components/Surfaces/Card/Card.vue'
import Container from '@/Components/Surfaces/Grid/Container.vue'
import Row from '@/Components/Surfaces/Grid/Row.vue'
import MasterLayout from '@/View/Layouts/MasterLayout.vue'
import { Head } from '@inertiajs/vue3'
import { isEmpty } from 'lodash'
import { computed } from 'vue'

const props = defineProps({
    pageTitle: {
        type: String,
        required: true,
    },
    metaDescription: {
        type: String,
        required: true,
    },
    body: {
        type: String,
        required: true,
    },
    faqItems: {
        type: Array,
        required: true,
    },
    contactUsText: {
        type: String,
        required: true,
    },
    hubspotFormId: {
        type: String,
        required: true,
    },
    featuredBlock: {
        type: Object,
        required: false,
    },
})

const hasFaqs = computed(() => !isEmpty(props.faqItems))
const hasHubspotFormId = computed(() => !isEmpty(props.hubspotFormId))
const hasFeaturedBlock = computed(() => !isEmpty(props.featuredBlock))
</script>

<template>
    <Head>
        <title>{{ pageTitle }}</title>
        <meta :content="metaDescription" name="description" />
    </Head>
    <MasterLayout wrapper-class="mt-0 md:mt-0">
        <div class="bg-accent py-14 md:py-28">
            <Container>
                <Row>
                    <div class="col-span-full text-center md:col-span-10 md:col-start-2 lg:col-span-6 lg:col-start-4">
                        <h1 class="text-lg lg:mb-2 lg:text-2xl">{{ pageTitle }}</h1>
                        <div class="[&>h2]:text-primary" v-html="body"></div>
                    </div>
                </Row>

                <FaqWrapper v-if="hasFaqs" :faqs="faqItems" class="mt-8 md:mt-16" />
            </Container>
        </div>

        <Container class="my-14 md:my-16">
            <Row>
                <div class="col-span-full md:col-span-6">
                    <div
                        class="prose max-w-none prose-headings:text-primary [&>h3]:text-2xl [&>h3]:md:mb-12 [&>h3]:md:text-3xl"
                        v-html="contactUsText"></div>
                </div>
                <div v-if="hasHubspotFormId" class="col-span-full md:col-span-6 lg:col-span-5 lg:col-start-8">
                    <div class="rounded-3xl bg-accent p-6 md:p-12">
                        <HubspotForm :hubspot-form-id="hubspotFormId" />
                    </div>
                </div>
            </Row>

            <Row v-if="hasFeaturedBlock" class="mt-14 md:mt-16">
                <div class="col-span-full">
                    <Card
                        :body="featuredBlock.body"
                        :button-text="featuredBlock.button_text"
                        :button-url="featuredBlock.button_url"
                        :image="featuredBlock.image_url"
                        :title="featuredBlock.title"
                        orientation="landscape" />
                </div>
            </Row>
        </Container>
    </MasterLayout>
</template>
