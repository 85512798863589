<script setup>
import { computed } from 'vue'
import { twMerge } from '@/Composables/External/twMerge.js'

const props = defineProps({
    fullHeight: {
        type: Boolean,
        default: false,
    },
    class: {
        type: String,
        default: '',
    },
})

const classNames = computed(() => {
    return twMerge('table--striped table--hover table min-w-full divide-y divide-neutral-200', props.fullHeight && 'h-full', props.class)
})
</script>
<template>
    <table :class="classNames">
        <slot></slot>
    </table>
</template>
