<script setup>
import Button from '@/Components/Forms/Buttons/Button.vue'
import { computed } from 'vue'
import Row from '../../Layouts/Grid/Row.vue'
import { isEmpty } from 'lodash'

const props = defineProps({
    image: {
        type: String,
        required: true,
    },
    subtitle: {
        type: String,
    },
    title: {
        type: String,
        required: true,
    },
    body: {
        type: String,
        required: true,
    },
    buttonText: {
        type: String,
    },
    buttonUrl: {
        type: String,
    },
    titleAsHeading: {
        type: Boolean,
        default: false,
    },
})

const hasButton = computed(() => {
    return !isEmpty(props.buttonText) && !isEmpty(props.buttonUrl)
})
</script>

<template>
    <div
        class="relative flex items-center overflow-hidden rounded-3xl p-4 pt-[50%] after:absolute after:inset-0 after:z-[1] after:bg-gradient-to-r after:from-black after:to-transparent after:opacity-70 md:p-8 lg:min-h-[426px] lg:p-12">
        <img :src="image" :alt="title" class="absolute inset-0 size-full object-cover object-center" />

        <Row class="relative z-10">
            <div class="col-span-full text-white md:col-span-8 xl:col-span-6">
                <p v-if="subtitle" class="mb-2 text-base font-bold text-primary md:mb-3 md:text-2xl">{{ subtitle }}</p>
                <component :is="titleAsHeading ? 'h1' : 'h2'">
                    {{ title }}
                </component>

                <div v-html="body"></div>

                <Button v-if="hasButton" class="mt-4 inline-block md:mt-6" color="secondary" :url="buttonUrl">{{ buttonText }}</Button>
            </div>
        </Row>
    </div>
</template>
