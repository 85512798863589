<script setup>
import Panel from '@/Components/Surfaces/Card/Panel.vue'
import moment from '@/Composables/External/moment.js'
import ProductSummary from '@/View/Checkout/Form/ProductSummary.vue'
import usePriceFormatter from '@/Composables/usePriceFormatter.js'
import { isNil } from 'lodash'
import { computed } from 'vue'

const priceFormatter = usePriceFormatter()

const props = defineProps({
    subtotal: {
        type: Number,
        required: true,
    },
    grandTotal: {
        type: Number,
        required: true,
    },
    items: {
        type: Array,
        required: true,
    },
    deliveryDate: {
        type: String,
    },
    transportFee: {
        type: Number,
        default: null,
    },
})

const formattedDate = computed(() => moment(props.deliveryDate).format('D MMMM Y'))

const calculatedTotal = computed(() => {
    return isNil(props.transportFee) ? props.grandTotal : props.grandTotal + props.transportFee
})
</script>

<template>
    <Panel class="bg-accent md:p-5">
        <div class="space-y-4">
            <span v-if="deliveryDate">{{ $trans.get('page.checkout.product-summary.earliest-delivery-date', { date: formattedDate }) }}</span>
            <ProductSummary :products="items" />
        </div>
    </Panel>

    <Panel class="sticky top-5 mt-4 bg-accent md:p-5">
        <section aria-labelledby="summary-heading" class="col-span-full mt-4 md:mt-0 lg:col-span-4">
            <h2 id="summary-heading" class="text-2xl text-gray-900">{{ $trans.get('pages.cart.order-summary.title') }}</h2>

            <dl class="mt-6 space-y-2">
                <div class="flex items-center justify-between pt-4">
                    <dt class="text-base font-medium text-gray-900">{{ $trans.get('pages.cart.order-summary.subtotal') }}</dt>
                    <dd class="text-base font-medium text-gray-900">{{ priceFormatter.format(subtotal) }}</dd>
                </div>

                <div class="flex items-center justify-between pb-2 pt-4" v-if="transportFee">
                    <dt class="text-base font-medium text-gray-900">{{ $trans.get('pages.cart.order-summary.transport') }}</dt>
                    <dd class="text-base font-medium text-gray-900">{{ priceFormatter.format(transportFee) }}</dd>
                </div>

                <div class="flex items-center justify-between border-t border-gray-200 pt-4">
                    <dt class="text-base font-medium text-gray-900">{{ $trans.get('pages.cart.order-summary.order-total') }}</dt>
                    <dd class="text-base font-medium text-gray-900">{{ priceFormatter.format(calculatedTotal) }}</dd>
                </div>
            </dl>

            <slot></slot>
        </section>
    </Panel>
</template>
