<script setup>
import * as Icons from '@/Components/Icons'
import { computed } from 'vue'

const props = defineProps({
    icon: {
        type: String,
        required: true,
    },
})

const iconComponent = computed(() => {
    return Icons[props.icon] || null
})
</script>

<template>
    <component :is="iconComponent" v-if="iconComponent" />
</template>
