import { onMounted, reactive } from 'vue'
import { debounce } from 'lodash'
// Need to change these constant to imported tailwind config values.
export const screens = {
    xs: 360,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
}

const calculateBreakpoint = (width) => {
    if (width <= screens.xs) return 'xs'
    if (width > screens.xs && width <= screens.sm) return 'sm'
    if (width > screens.sm && width <= screens.md) return 'md'
    if (width > screens.md && width <= screens.lg) return 'lg'
    if (width > screens.lg) return 'xl'

    return 'all'
}

const isBreakpointUp = (width, target) => width > screens[target]
const isBreakpointDown = (width, target) => width < screens[target]

// Main logic
const useBreakpoint = () => {
    const breakpoints = reactive({
        width: 0,
        height: 0,
        current: 'xs',
        up: (point) => isBreakpointUp(window.innerWidth, point),
        down: (point) => isBreakpointDown(window.innerWidth, point),
    })

    const updateBreakpoints = () => {
        breakpoints.width = window.innerWidth
        breakpoints.height = window.innerHeight
        breakpoints.current = calculateBreakpoint(window.innerWidth)
        breakpoints.up = (point) => isBreakpointUp(window.innerWidth, point)
        breakpoints.down = (point) => isBreakpointDown(window.innerWidth, point)
    }

    onMounted(() => {
        updateBreakpoints()
        window.addEventListener(
            'resize',
            debounce(() => {
                updateBreakpoints()
            }, 200),
        )
    })

    return { breakpoints, updateBreakpoints }
}

export default useBreakpoint
