<template>
    <svg width="16" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.51 17.51" aria-hidden="true">
        <path
            d="M8.21,11.94c.07,.07,.16,.13,.25,.17,.07,.03,.14,.03,.2,.04,.03,0,.05,.02,.08,.02,0,0,0,0,0,0s0,0,0,0c.1,0,.2-.02,.29-.06,.02,0,.03-.02,.05-.03,.07-.03,.14-.07,.19-.13l3.56-3.56c.29-.29,.29-.77,0-1.06s-.77-.29-1.06,0l-2.28,2.28V.75c0-.41-.34-.75-.75-.75s-.75,.34-.75,.75V9.61l-2.27-2.27c-.29-.29-.77-.29-1.06,0s-.29,.77,0,1.06l3.55,3.55Z"
            fill="currentColor" />
        <path
            d="M16.76,12.45c-.41,0-.75,.34-.75,.75,0,1.55-1.26,2.81-2.81,2.81H4.31c-1.55,0-2.81-1.26-2.81-2.81,0-.41-.34-.75-.75-.75s-.75,.34-.75,.75c0,2.38,1.93,4.31,4.31,4.31H13.2c2.38,0,4.31-1.93,4.31-4.31,0-.41-.34-.75-.75-.75Z"
            fill="currentColor" />
    </svg>
</template>
