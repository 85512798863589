<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path
            d="M7.5 10.998V6.99805C7.5 5.67196 8.02678 4.40019 8.96447 3.46251C9.90215 2.52483 11.1739 1.99805 12.5 1.99805C13.8261 1.99805 15.0979 2.52483 16.0355 3.46251C16.9732 4.40019 17.5 5.67196 17.5 6.99805V10.998M5.5 10.998H19.5C20.6046 10.998 21.5 11.8935 21.5 12.998V19.998C21.5 21.1026 20.6046 21.998 19.5 21.998H5.5C4.39543 21.998 3.5 21.1026 3.5 19.998V12.998C3.5 11.8935 4.39543 10.998 5.5 10.998Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
</template>
