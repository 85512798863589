import moment from '@/Composables/External/moment.js'

export function formatStockPeriodLocalized(date) {
    if (!date) {
        return ''
    }

    return moment(date).local().format('DD MMM')
}

export function formatLongLocalized(date) {
    if (!date) {
        return ''
    }

    return moment(date).local().format('dddd, DD MMMM YYYY')
}

export function formatDayMonthShort(date) {
    if (!date) {
        return ''
    }

    return moment(date).local().format('ddd, DD MMM Y')
}

export function formatDateToYmd(date) {
    if (!date) {
        return ''
    }

    return moment(date).local().format('YYYY-MMM-DD')
}
