<script setup>
import { computed, inject } from 'vue'

import Table from '@/Components/Table/Table.vue'
import TableRow from '@/Components/Table/TableRow.vue'
import TableBody from '@/Components/Table/TableBody.vue'
import TableHeader from '@/Components/Table/TableHeader.vue'
import TableDataCell from '@/Components/Table/TableDataCell.vue'

import UrlDownloader from '@/View/MyAccount/UrlDownloader.vue'
import StatusPill from '@/View/MyAccount/StatusPill.vue'

import usePriceFormatter from '@/Composables/usePriceFormatter.js'
import { formatDayMonthShort } from '@/Composables/useDateFormatter.js'

import { isEmpty } from 'lodash'

const priceFormatter = usePriceFormatter()
const trans = inject('trans')

const props = defineProps({
    data: {
        type: Array,
        required: true,
    },
    loading: {
        type: Boolean,
    },
    error: {
        type: Boolean,
    },
})

const hasSalesOrder = computed(() => !isEmpty(props.data))

const columns = [
    { name: trans.get('generic.options'), srOnly: true },
    { name: trans.get('page.my-account.sales-order.table-column.order-number'), class: 'pl-4 md:pl-0' },
    { name: trans.get('page.my-account.sales-order.table-column.status'), class: '!text-center' },
    { name: trans.get('page.my-account.sales-order.table-column.total'), class: 'hidden lg:table-cell' },
    { name: trans.get('page.my-account.sales-order.table-column.order-date'), class: 'hidden md:table-cell' },
    { name: trans.get('page.my-account.sales-order.table-column.reference'), class: 'hidden md:table-cell' },
    { name: trans.get('page.my-account.sales-order.table-column.delivery-date'), class: 'hidden md:table-cell' },
]

const statusList = {
    pending: {
        class: 'bg-warning',
        name: trans.get('page.my-account.sales-order.status.pending'),
    },
    cancelled: {
        class: 'bg-danger-600 text-white',
        name: trans.get('page.my-account.sales-order.status.cancelled'),
    },
    completed: {
        class: 'bg-primary text-white',
        name: trans.get('page.my-account.sales-order.status.delivered'),
    },
}

const noResult = computed(() => !hasSalesOrder.value && !props.loading)
</script>
<template>
    <div class="mt-8 flow-root">
        <div class="-mx-6 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-12">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <Table :full-height="!hasSalesOrder">
                    <TableHeader :columns="columns" />
                    <TableBody
                        :column-length="columns.length"
                        :no-result="noResult"
                        :loading="loading"
                        :error-occurred="error"
                        :empty-message="$trans.get('page.my-account.sales-orders.table-component.no-results')">
                        <template v-for="order in data" :key="order.number">
                            <TableRow>
                                <TableDataCell class="p-0 sm:px-2 sm:py-4">
                                    <UrlDownloader
                                        sr-only
                                        :button-name="$trans.get('page.my-account.sales-order.order-confirmation')"
                                        :file-name="`Sales Order-${order.number}.pdf`"
                                        :file-url="$route('profit.sales-order.file-download', { fileId: order.report, entityId: order.number })" />
                                </TableDataCell>
                                <TableDataCell class="font-medium text-black md:pl-0">
                                    <div class="flex gap-1">
                                        <div>
                                            <span class="text-black">{{ order.number }}</span>
                                            <dl class="font-light lg:hidden">
                                                <dt class="sr-only">{{ $trans.get('page.my-account.sales-order.table-column.total') }}</dt>
                                                <dd class="mt-1">{{ priceFormatter.format(order.grandTotal) }}</dd>
                                                <dt class="sr-only sm:hidden">
                                                    {{ $trans.get('page.my-account.sales-order.table-column.order-date') }}
                                                </dt>
                                                <dd class="mt-1 md:hidden">{{ formatDayMonthShort(order.orderDate) }}</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </TableDataCell>
                                <TableDataCell class="w-1/2 sm:w-auto sm:pl-0">
                                    <div class="flex w-full justify-between">
                                        <div class="grow items-center">
                                            <dt class="sr-only sm:hidden">
                                                {{ $trans.get('page.my-account.sales-order.table-column.reference') }}
                                            </dt>
                                            <dd class="md:hidden">{{ order.reference }}</dd>
                                            <dt class="sr-only sm:hidden">
                                                {{ $trans.get('page.my-account.sales-order.table-column.delivery-date') }}
                                            </dt>
                                            <dd class="mt-1 truncate md:hidden">{{ formatDayMonthShort(order.deliveryDate) }}</dd>
                                            <StatusPill :status="order.status" :status-list="statusList" />
                                        </div>
                                    </div>
                                </TableDataCell>
                                <TableDataCell class="hidden lg:table-cell">
                                    {{ priceFormatter.format(order.grandTotal) }}
                                </TableDataCell>
                                <TableDataCell class="hidden md:table-cell">
                                    {{ formatDayMonthShort(order.orderDate) }}
                                </TableDataCell>
                                <TableDataCell class="hidden md:table-cell">
                                    {{ order.reference }}
                                </TableDataCell>
                                <TableDataCell class="hidden md:table-cell">
                                    <span class="sr-only sm:hidden">
                                        {{ $trans.get('page.my-account.sales-order.table-column.delivery-date') }}
                                    </span>
                                    <span>{{ formatDayMonthShort(order.deliveryDate) }}</span>
                                </TableDataCell>
                            </TableRow>
                        </template>
                    </TableBody>
                </Table>
            </div>
        </div>
    </div>
</template>
