<script setup>
// ** Vue imports
import { inject } from 'vue'

// ** Inertiajs imports
import { useForm } from '@inertiajs/vue3'

// ** Component imports
import Row from '@/Components/Surfaces/Grid/Row.vue'
import Input from '@/Components/Forms/Input/Input.vue'
import Container from '@/Components/Surfaces/Grid/Container.vue'
import MasterLayout from '../../View/Layouts/MasterLayout.vue'
import InlineFormItem from '@/Components/Forms/Layouts/InlineFormItem.vue'
import Loading from '@/Components/Icons/Loading.vue'
import Send from '@/Components/Icons/Send.vue'
import Button from '@/Components/Forms/Buttons/Button.vue'
import Panel from '@/Components/Surfaces/Card/Panel.vue'
import Phone from '@/Components/Icons/Phone.vue'
import Email from '@/Components/Icons/Email.vue'
import BannerImage from '@/Components/Images/BannerImage.vue'

const route = inject('route')

defineProps({
    pageTitle: {
        type: String,
    },
    subtitle: {
        type: String,
    },
    metaDescription: {
        type: String,
    },
    body: {
        type: String,
    },
    bannerImageUrl: {
        type: String,
    },
    aboutUsText: {
        type: String,
    },
    uspsText: {
        type: String,
    },
    contactInfoText: {
        type: String,
    },
    phoneNumber: {
        type: String,
    },
    emailAddress: {
        type: String,
    },
})

const form = useForm({
    email: '',
})

const onSubmit = () => {
    form.post(route('auth.forgot-password.submit'))
    form.reset()
}
</script>

<template>
    <Head>
        <title>{{ pageTitle }}</title>
        <meta v-if="metaDescription" name="description" :content="metaDescription" />
    </Head>
    <MasterLayout wrapper-class="md:mt-24 mb-8">
        <Container class="space-y-12">
            <Row>
                <div class="col-span-12">
                    <h3>{{ pageTitle }}</h3>
                    <p>{{ subtitle }}</p>
                </div>
            </Row>
            <Row class="mt-10 md:mt-20">
                <div class="col-span-full lg:col-span-6">
                    <Panel>
                        <form id="set-password-form" @submit.prevent="onSubmit" class="space-y-6">
                            <h4 class="mb-6">{{ $trans.get('page.forgot-password.form-label.title') }}</h4>
                            <InlineFormItem
                                :label="$trans.get('page.forgot-password.form-label.email')"
                                id="email-field"
                                required
                                :errors="form.errors.email">
                                <Input
                                    input-type="text"
                                    id="email-input"
                                    class="w-full"
                                    v-model="form.email"
                                    @input="form.clearErrors()"
                                    required
                                    :errors="form.errors.email"></Input>
                            </InlineFormItem>
                            <div class="ml-auto mt-2 w-full md:w-2/3">
                                <Button
                                    type="submit"
                                    color="primary"
                                    class="block w-full"
                                    :disabled="form.processing || form.hasErrors || !form.email"
                                    :class="{ 'opacity-50 hover:bg-primary': form.processing }"
                                    @keydown="form.clearErrors()">
                                    <template v-slot:start>
                                        <Loading v-if="form.processing" />
                                        <Send v-else />
                                    </template>
                                    {{ $trans.get('page.forgot-password.form-label.submit') }}
                                </Button>
                            </div>
                            <div>
                                <p class="text-primary" v-html="$page.props.flash.message"></p>
                            </div>
                        </form>
                    </Panel>
                </div>
                <div class="col-span-full mt-8 md:mt-12 lg:col-span-6 lg:mt-0 xl:col-span-5 xl:col-start-8">
                    <div v-html="body" class="primary-dots prose"></div>
                </div>
            </Row>

            <Row class="mt-8 md:mt-12">
                <div class="col-span-full">
                    <div class="grid gap-8 xl:grid-cols-3">
                        <div class="space-y-4">
                            <div class="font-light" v-html="aboutUsText" />
                        </div>

                        <div class="primary-dots prose font-light text-black" v-html="uspsText"></div>

                        <div class="space-y-4">
                            <div class="font-light" v-html="contactInfoText" />

                            <div class="grid gap-4">
                                <a :href="`tel:${phoneNumber}`" class="flex items-center gap-4 font-bold no-underline hocus:underline">
                                    <Phone class="size-5" />
                                    {{ phoneNumber }}
                                </a>

                                <a :href="`mailto:${emailAddress}`" class="flex items-center gap-4 font-bold no-underline hocus:underline">
                                    <Email class="size-5" />
                                    {{ emailAddress }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-span-full mt-8 md:mt-12">
                    <BannerImage :image="bannerImageUrl" :title="pageTitle" />
                </div>
            </Row>
        </Container>
    </MasterLayout>
</template>
