<script setup>
// ** Vue imports
import { inject, computed, ref } from 'vue'

// ** Inertiajs imports
import { Head, useForm } from '@inertiajs/vue3'

// ** View imports
import MasterLayout from '@/View/Layouts/MasterLayout.vue'

// ** Components imports
import Row from '@/Components/Surfaces/Grid/Row.vue'
import Send from '@/Components/Icons/Send.vue'
import Button from '@/Components/Forms/Buttons/Button.vue'
import Loading from '@/Components/Icons/Loading.vue'
import Container from '@/Components/Surfaces/Grid/Container.vue'
import InlineFormItem from '@/Components/Forms/Layouts/InlineFormItem.vue'
import ListChecker from '@/View/Auth/ListChecker.vue'
import Eye from '@/Components/Icons/Eye.vue'
import EyeOff from '@/Components/Icons/EyeOff.vue'
import Input from '@/Components/Forms/Input/Input.vue'
import Phone from '@/Components/Icons/Phone.vue'
import Email from '@/Components/Icons/Email.vue'
import Panel from '@/Components/Surfaces/Card/Panel.vue'
import BannerImage from '@/Components/Images/BannerImage.vue'

const route = inject('route')
const trans = inject('trans')

const props = defineProps({
    pageTitle: {
        type: String,
    },
    subtitle: {
        type: String,
    },
    metaDescription: {
        type: String,
    },
    body: {
        type: String,
    },
    bannerImageUrl: {
        type: String,
    },
    aboutUsText: {
        type: String,
    },
    uspsText: {
        type: String,
    },
    contactInfoText: {
        type: String,
    },
    phoneNumber: {
        type: String,
    },
    emailAddress: {
        type: String,
    },
    requestToken: {
        type: String,
        required: true,
    },
    requestEmailAddress: {
        type: String,
    },
})

const showPassword = ref(false)
const showVerifyPassword = ref(false)

const form = useForm({
    email: props.requestEmailAddress ?? '',
    password: '',
    password_confirmation: '',
})

const isLongEnough = computed(() => {
    return form.password.length >= 8
})

const hasCapitalLetter = computed(() => {
    return /[A-Z]/.test(form.password)
})

const hasLowercaseLetter = computed(() => {
    return /[a-z]/.test(form.password)
})

const hasNumber = computed(() => {
    return /[0-9]/.test(form.password)
})

const hasSpecialCharacter = computed(() => {
    return /[!@#$%^&*(),.?":{}|<>]/.test(form.password)
})

const isValidPassword = computed(() => {
    return isLongEnough.value && hasCapitalLetter.value && hasLowercaseLetter.value && hasNumber.value && hasSpecialCharacter.value
})

const passwordMatched = computed(() => {
    return form.password === form.password_confirmation
})

const onSubmit = () => {
    if (!passwordMatched.value) {
        form.setError('password_confirmation', trans.get('generic.validation.password-mismatched'))

        return
    }

    submit()
}

const submit = () => {
    form.transform((data) => ({
        ...data,
        token: props.requestToken,
    })).post(route('auth.set-password.submit'))
}

const PasswordRules = computed(() => {
    return [
        { condition: isLongEnough.value, label: trans.get('page.set-password.form-rule.least-character') },
        { condition: hasCapitalLetter.value, label: trans.get('page.set-password.form-rule.capital') },
        { condition: hasLowercaseLetter.value, label: trans.get('page.set-password.form-rule.lowercase') },
        { condition: hasNumber.value, label: trans.get('page.set-password.form-rule.number') },
        { condition: hasSpecialCharacter.value, label: trans.get('page.set-password.form-rule.special') },
    ]
})
</script>
<template>
    <Head>
        <title>{{ pageTitle }}</title>
        <meta v-if="metaDescription" name="description" :content="metaDescription" />
    </Head>
    <MasterLayout wrapper-class="md:mt-24 mb-8">
        <Container class="space-y-12">
            <Row>
                <div class="col-span-12">
                    <h3>{{ pageTitle }}</h3>
                    <p>{{ subtitle }}</p>
                </div>
            </Row>
            <Row class="mt-10 md:mt-20">
                <div class="col-span-full lg:col-span-6">
                    <Panel>
                        <form id="set-password-form" @submit.prevent="onSubmit" class="space-y-6">
                            <h4 class="mb-6">{{ $trans.get('page.set-password.form-label.title') }}</h4>
                            <InlineFormItem
                                :label="$trans.get('page.set-password.form-label.email')"
                                id="email-field"
                                required
                                :errors="form.errors.email">
                                <Input
                                    input-type="text"
                                    id="email-input"
                                    class="w-full"
                                    v-model="form.email"
                                    @input="form.clearErrors()"
                                    required
                                    :errors="form.errors.email"></Input>
                            </InlineFormItem>
                            <InlineFormItem
                                :label="$trans.get('page.set-password.form-label.password')"
                                id="password-field"
                                required
                                :errors="form.errors.password">
                                <div class="relative flex flex-col gap-4">
                                    <Input
                                        :input-type="showPassword ? 'text' : 'password'"
                                        id="password-input"
                                        class="w-full pr-10"
                                        v-model="form.password"
                                        @input="form.clearErrors()"
                                        required
                                        :errors="form.errors.password" />
                                    <div class="absolute right-3 top-2">
                                        <Eye v-if="!showPassword" class="size-5 cursor-pointer" @click="showPassword = true" />
                                        <EyeOff v-else class="size-5 cursor-pointer" @click="showPassword = false" />
                                    </div>

                                    <ListChecker :rules="PasswordRules" />
                                </div>
                            </InlineFormItem>

                            <InlineFormItem
                                :label="$trans.get('page.set-password.form-label.verifyPassword')"
                                id="verifyPassword-field"
                                required
                                :errors="form.errors.password_confirmation">
                                <div class="relative">
                                    <Input
                                        :input-type="showVerifyPassword ? 'text' : 'password'"
                                        id="password_confirmation-input"
                                        class="w-full pr-10"
                                        v-model="form.password_confirmation"
                                        @input="form.clearErrors()"
                                        required
                                        :errors="form.errors.password_confirmation" />
                                    <div class="absolute right-3 top-2">
                                        <Eye v-if="!showVerifyPassword" class="size-5 cursor-pointer" @click="showVerifyPassword = true" />
                                        <EyeOff v-else class="size-5 cursor-pointer" @click="showVerifyPassword = false" />
                                    </div>
                                </div>
                            </InlineFormItem>

                            <div class="ml-auto mt-2 w-full md:w-2/3">
                                <Button
                                    type="submit"
                                    color="primary"
                                    class="block w-full"
                                    :disabled="form.processing || form.hasErrors || !isValidPassword"
                                    :class="{ 'opacity-50 hover:bg-primary': form.processing }"
                                    @keydown="form.clearErrors()">
                                    <template v-slot:start>
                                        <Loading v-if="form.processing" />
                                        <Send v-else />
                                    </template>
                                    {{ $trans.get('page.set-password.form-label.submit') }}
                                </Button>
                            </div>
                        </form>
                    </Panel>
                </div>
                <div class="col-span-full mt-8 md:mt-12 lg:col-span-6 lg:mt-0 xl:col-span-5 xl:col-start-8">
                    <div v-html="body" class="primary-dots prose"></div>
                </div>
            </Row>
            <Row class="mt-8 md:mt-12">
                <div class="col-span-full">
                    <div class="grid gap-8 xl:grid-cols-3">
                        <div class="space-y-4">
                            <div class="font-light" v-html="aboutUsText" />
                        </div>

                        <div class="primary-dots prose font-light text-black" v-html="uspsText"></div>

                        <div class="space-y-4">
                            <div class="font-light" v-html="contactInfoText" />

                            <div class="grid gap-4">
                                <a :href="`tel:${phoneNumber}`" class="flex items-center gap-4 font-bold no-underline hocus:underline">
                                    <Phone class="size-5" />
                                    {{ phoneNumber }}
                                </a>

                                <a :href="`mailto:${emailAddress}`" class="flex items-center gap-4 font-bold no-underline hocus:underline">
                                    <Email class="size-5" />
                                    {{ emailAddress }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-full mt-8 md:mt-12">
                    <BannerImage :image="bannerImageUrl" :title="pageTitle" />
                </div>
            </Row>
        </Container>
    </MasterLayout>
</template>
