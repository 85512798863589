<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
        <path
            d="M13.4125 17.9248C11.9125 17.9248 10.7125 19.1248 10.7125 20.6248C10.7125 22.1248 11.9125 23.3248 13.4125 23.3248C14.9125 23.3248 16.1125 22.1248 16.1125 20.6248C16.1125 19.1623 14.875 17.9248 13.4125 17.9248ZM13.4125 21.6748C12.85 21.6748 12.4 21.2248 12.4 20.6623C12.4 20.0998 12.85 19.6498 13.4125 19.6498C13.975 19.6498 14.425 20.0998 14.425 20.6623C14.425 21.1873 13.9375 21.6748 13.4125 21.6748Z"
            fill="currentColor" />
        <path
            d="M6.28752 17.9248C4.78752 17.9248 3.58752 19.1248 3.58752 20.6248C3.58752 22.1248 4.78752 23.3248 6.28752 23.3248C7.78752 23.3248 8.98752 22.1248 8.98752 20.6248C8.98752 19.1623 7.75002 17.9248 6.28752 17.9248ZM6.28752 21.6748C5.72502 21.6748 5.27502 21.2248 5.27502 20.6623C5.27502 20.0998 5.72502 19.6498 6.28752 19.6498C6.85002 19.6498 7.30002 20.0998 7.30002 20.6623C7.30002 21.1873 6.85002 21.6748 6.28752 21.6748Z"
            fill="currentColor" />
        <path
            d="M21.325 0.674805H19.3C18.4 0.674805 17.6125 1.3498 17.5 2.2498L16.9 6.5623H2.08754C1.71254 6.5623 1.33754 6.7498 1.07504 7.0498C0.850042 7.3498 0.737543 7.76231 0.850043 8.13731C0.850043 8.17481 0.850043 8.1748 0.850043 8.21231L3.17504 15.2248C3.32504 15.7498 3.81254 16.1248 4.37504 16.1248H14.725C16.1125 16.1248 17.3125 15.0748 17.5 13.6873L19.075 2.4748C19.075 2.3998 19.15 2.3623 19.225 2.3623H21.25C21.7 2.3623 22.1125 1.9873 22.1125 1.4998C22.1125 1.0123 21.775 0.674805 21.325 0.674805ZM15.8875 13.4248C15.8125 13.9873 15.325 14.3998 14.7625 14.3998H4.71254L2.68754 8.2498H16.6375L15.8875 13.4248Z"
            fill="currentColor" />
    </svg>
</template>
