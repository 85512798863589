<script setup>
import Button from '@/Components/Forms/Buttons/Button.vue'
import Pencil from '@/Components/Icons/Pencil.vue'
import Loading from '@/Components/Icons/Loading.vue'
import Trash from '@/Components/Icons/Trash.vue'
import usePriceFormatter from '@/Composables/usePriceFormatter.js'
import StockAvailability from '@/View/Product/StockAvailability.vue'
import { router } from '@inertiajs/vue3'
import { inject, ref, watch } from 'vue'
import { debounce } from 'lodash'

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
})

const route = inject('route')
const priceFormatter = usePriceFormatter()
const loading = ref(false)
const quantity = ref(props.item.quantity)

const update = debounce(() => {
    loading.value = true

    router.get(route('cart.item.update', props.item.id), {
        sku: props.item.sku,
        quantity: quantity.value,
        packingUnit: props.item.packingUnit,
    })
}, 500)

const handleRemove = () => {
    router.get(route('cart.item.destroy', props.item.id))
}

watch(quantity, update, { deep: true })
</script>
<template>
    <li class="flex rounded-lg px-4 py-6 transition-all duration-200 even:bg-gray-50 hover:!bg-gray-50 sm:py-10 lg:even:bg-transparent">
        <div class="shrink-0">
            <img :src="item.product.thumbnail" :alt="item.title" class="size-24 rounded-md object-contain object-center sm:size-48" />
        </div>

        <div class="ml-4 flex flex-1 flex-col justify-between sm:ml-6 md:justify-center">
            <div class="grid gap-4 pr-9 sm:pr-0 md:grid-cols-3 md:gap-6 md:gap-y-8">
                <div class="flex flex-col">
                    <h3 class="text-lg">
                        <a :href="item.product.url" class="font-medium text-gray-700 hover:text-gray-800">{{ item.title }}</a>
                    </h3>

                    <StockAvailability :in-stock="item.product.in_stock" />
                </div>

                <div class="sm:mt-0 sm:pr-9">
                    <label :for="`product-quantity-${item.product.id}`">{{ $trans.get('view.product.quantity') }}</label>
                    <input
                        v-model="quantity"
                        :id="`product-quantity-${item.product.id}`"
                        type="number"
                        min="0"
                        max="99"
                        step="1"
                        :disabled="loading" />
                </div>

                <div>
                    <span>{{ $trans.get('view.product.price') }}</span>
                    <span class="mt-1 flex items-center gap-2">
                        <Loading v-if="loading" class="text-primary" />
                        {{ priceFormatter.format(item.total) }}
                    </span>
                    <span class="mt-1 text-xs text-gray-500">{{ priceFormatter.format(item.price) }} / {{ item.packingUnit }}</span>
                </div>

                <div class="col-span-full flex flex-wrap gap-4">
                    <Button :url="item.product.url" type="button" full-width="xs">
                        <template v-slot:start>
                            <Pencil />
                        </template>
                        {{ $trans.get('pages.cart.edit') }}
                    </Button>

                    <Button id="remove-item-from-cart" @click="handleRemove" type="button" color="outlined" full-width="xs">
                        <template v-slot:start>
                            <Trash />
                        </template>
                        {{ $trans.get('pages.cart.remove') }}
                    </Button>
                </div>
            </div>
        </div>
    </li>
</template>
