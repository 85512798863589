<script setup></script>

<template>
    <svg fill="none" height="52" viewBox="0 0 113 52" width="113" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1308_1132)">
            <path
                d="M40.3097 37.672C39.5038 38.6507 38.6048 39.3854 37.6374 39.8557C36.6731 40.3235 35.486 40.561 34.1097 40.561C33.2131 40.561 32.3661 40.39 31.5911 40.0532C30.8118 39.7146 30.1312 39.2496 29.5691 38.6713C29.001 38.0876 28.5267 37.3952 28.1589 36.614C27.8382 35.9369 27.6446 35.2127 27.5787 34.4574L24.207 37.8607C24.7739 39.2014 25.5423 40.377 26.4945 41.3533C27.4656 42.3485 28.6205 43.1261 29.9267 43.6645C31.2353 44.2041 32.6425 44.4774 34.1097 44.4774C36.0668 44.4774 37.8389 44.1254 39.3762 43.4306C40.9105 42.7382 42.4272 41.5473 43.8834 39.8909L44.1218 39.62L40.4827 37.4628L40.3097 37.672Z"
                fill="white"></path>
            <path
                d="M44.0404 28.6607C43.468 27.2899 42.6888 26.0932 41.7244 25.1045C40.9028 24.2622 39.9457 23.5769 38.8737 23.0573L35.8463 26.1132C36.1004 26.1802 36.3472 26.2566 36.5765 26.3548C37.2904 26.6622 37.9117 27.0619 38.4242 27.5427C38.9366 28.0247 39.3571 28.5713 39.6735 29.1685C39.9276 29.6499 40.1363 30.1419 40.2954 30.635H31.3662L27.5874 34.4492H44.9013V33.2295C44.9019 31.5708 44.6115 30.0337 44.0404 28.6607Z"
                fill="white"></path>
            <path
                d="M15.7753 27.8824C16.9847 29.2114 17.597 31.0101 17.597 33.2302C17.597 35.4502 16.9841 37.2495 15.7753 38.5773C14.5768 39.8939 12.809 40.5617 10.5203 40.5617C9.68961 40.5617 8.87407 40.3747 8.09543 40.0068C7.31014 39.6371 6.60471 39.121 6.00032 38.4732C5.39411 37.8237 4.89861 37.0408 4.52835 36.145C4.15809 35.2539 3.97054 34.2729 3.97054 33.2302C3.97054 32.1198 4.15809 31.1047 4.52835 30.213C4.89922 29.3178 5.3935 28.5443 5.99729 27.9136C6.59987 27.2841 7.30409 26.785 8.09059 26.43C8.87225 26.0767 9.6896 25.8981 10.5197 25.8981C12.8072 25.8975 14.5756 26.5652 15.7753 27.8824ZM17.5467 24.8677C16.6284 23.9495 15.6101 23.2536 14.5127 22.7951C13.2155 22.2549 11.7944 21.981 10.2898 21.981C8.81901 21.981 7.42449 22.2555 6.14431 22.7963C4.86655 23.3371 3.7358 24.1147 2.78293 25.1069C1.83187 26.0979 1.07622 27.2958 0.538377 28.6677C0.00174233 30.0373 -0.270508 31.5732 -0.270508 33.2307C-0.270508 34.8901 0.00174233 36.4248 0.538377 37.7926C1.07501 39.1633 1.83066 40.3612 2.78293 41.3534C3.73822 42.3485 4.86897 43.1262 6.14431 43.6652C7.42267 44.2054 8.8172 44.4793 10.2898 44.4793C11.7956 44.4793 13.2168 44.2054 14.5127 43.6658C15.6307 43.1997 16.6659 42.4867 17.597 41.5421V43.7157H21.838V7.54138H17.5982L17.5467 24.8677Z"
                fill="white"></path>
            <path
                d="M58.4411 21.9805C56.931 21.9805 55.5516 22.2556 54.3392 22.7981C53.3434 23.2442 52.4782 23.9137 51.7595 24.7925V22.744H47.519V43.7146H51.7595V32.3132C51.7595 30.0708 52.3306 28.425 53.4571 27.4216C54.5909 26.4106 56.0683 25.8975 57.8494 25.8975C59.3752 25.8975 60.4152 26.3207 61.0299 27.1912C61.6603 28.0858 61.9804 29.2955 61.9804 30.7861V43.7146H66.2214V30.4294C66.2214 27.8225 65.5499 25.7435 64.2255 24.2499C62.8891 22.744 60.9434 21.9805 58.4411 21.9805Z"
                fill="white"></path>
            <path d="M73.0687 22.7439H68.8276V43.7145H73.0687V22.7439Z" fill="white"></path>
            <path
                d="M104.606 26.6433C104.299 25.7452 103.84 24.9411 103.244 24.2522C102.643 23.5603 101.897 23.0037 101.027 22.597C100.151 22.1879 99.1221 21.981 97.9683 21.981C96.3463 21.981 94.979 22.3413 93.9033 23.0519C92.9819 23.6626 92.0998 24.4132 91.2764 25.2896C90.7434 24.1722 90.0331 23.3493 89.1601 22.8391C88.1837 22.2696 86.9162 21.981 85.3934 21.981C84.5089 21.981 83.4792 22.2572 82.3339 22.8015C81.4065 23.2435 80.5958 23.9024 79.9163 24.7659V22.7445H75.6753V43.7151H79.9163V31.1928C79.9163 30.5233 80.05 29.8785 80.3138 29.2754C80.5794 28.6659 80.9333 28.1204 81.3647 27.6549C81.7949 27.1929 82.2952 26.822 82.8524 26.5528C83.4048 26.2871 83.9971 26.1519 84.6117 26.1519C85.7546 26.1519 86.6246 26.5158 87.2701 27.2646C87.9217 28.0205 88.252 29.085 88.252 30.4293V43.7145H92.4931V30.9389C92.4931 30.2706 92.6262 29.644 92.8875 29.0773C93.1519 28.5031 93.5113 27.994 93.9554 27.565C94.4025 27.1335 94.9137 26.7855 95.4745 26.5304C96.0269 26.2795 96.6028 26.1519 97.1873 26.1519C98.2618 26.1519 99.116 26.5175 99.7985 27.2699C100.481 28.0246 100.827 29.0873 100.827 30.4287V43.7139H105.068V29.5129C105.067 28.5025 104.912 27.5362 104.606 26.6433Z"
                fill="white"></path>
            <path
                d="M70.9484 12.5664C69.3137 12.5664 67.9839 13.8584 67.9839 15.4466C67.9839 17.0348 69.3137 18.3267 70.9484 18.3267C72.5831 18.3267 73.9129 17.0348 73.9129 15.4466C73.9129 13.8584 72.5831 12.5664 70.9484 12.5664Z"
                fill="#FFEB7B"></path>
        </g>
        <defs>
            <clipPath id="clip0_1308_1132">
                <rect fill="white" height="52" width="113"></rect>
            </clipPath>
        </defs>
    </svg>
</template>
