<script setup>
import { computed } from 'vue'

import PriceTag from '../../Components/Icons/PriceTag.vue'
import Button from '@/Components/Forms/Buttons/Button.vue'
import { groupBy } from 'lodash'

const props = defineProps({
    documents: {
        type: Array,
        required: true,
    },
})

const groupByType = (list) => {
    const grouped = groupBy(list, 'type')

    return Object.keys(grouped).map((type) => ({
        type,
        list: grouped[type],
    }))
}

const groupedItems = computed(() => groupByType(props.documents))
</script>

<template>
    <div class="mt-8 flex flex-col gap-4 py-3">
        <h6 class="block lg:hidden">{{ $trans.get('view.product.documents') }}</h6>
        <div v-for="{ type, list } in groupedItems" :key="type" class="group">
            <p class="mb-3 block text-sm xs:hidden">{{ type }}:</p>
            <div class="flex">
                <Button v-for="document in list" :key="document.id" type="a" color="primary" :download="document.file_url" :url="document.file_url">
                    <template v-slot:start>
                        <PriceTag class="size-4" />
                    </template>
                    <span class="line-clamp-1">
                        {{ document.title }}
                    </span>
                </Button>
            </div>
        </div>
    </div>
</template>
