<script setup>
import { computed } from 'vue'
import { twMerge } from '@/Composables/External/twMerge.js'

const props = defineProps({
    class: {
        type: String,
    },
})

const classNames = computed(() => {
    return twMerge([props.class, 'px-2 py-4 text-sm font-light text-neutral-500'])
})
</script>

<template>
    <td :class="classNames">
        <slot></slot>
    </td>
</template>
