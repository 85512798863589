<script setup>
import { computed, ref } from 'vue'

import CarouselImage from '@/Components/Surfaces/Carousel/CarouselImage.vue'
import ChevronLeft from '@/Components/Icons/ChevronLeft.vue'
import ChevronRight from '@/Components/Icons/ChevronRight.vue'

import useBreakpoint from '@/Composables/useBreakpoint'

import { Swiper, SwiperSlide } from '@/Composables/External/Swiper/swiper'

const props = defineProps({
    // Images to slides with
    // { path, order, alt }
    images: {
        type: Array,
        default: () => [],
        required: true,
    },
    indicators: {
        type: String,
        default: 'image',
        required: false,
    },
})

const { breakpoints } = useBreakpoint()
const currentIndex = ref(0)

const goToSlide = (index) => {
    currentIndex.value = index
}

const prevSlide = () => {
    currentIndex.value = currentIndex.value > 0 ? currentIndex.value - 1 : props.images.length - 1
}

const nextSlide = () => {
    currentIndex.value = currentIndex.value < props.images.length - 1 ? currentIndex.value + 1 : 0
}

const isActive = computed(() => (index) => index === currentIndex.value)
const needsCarousel = computed(() => props.images.length > 1)

const swiperOptions = {
    breakpoints: {
        0: {
            slidesPerView: 2.25,
            spaceBetween: 8,
        },
        1024: {
            slidesPerView: 3.25,
            spaceBetween: 8,
        },
        1400: {
            slidesPerView: 4.25,
            spaceBetween: 8,
        },
    },
}

const thumbsHidden = computed(() => breakpoints.down('xl'))
</script>

<template>
    <div id="default-carousel" class="relative w-full">
        <!-- Carousel wrapper -->
        <div class="relative aspect-[4/3] overflow-hidden rounded-3xl">
            <CarouselImage v-for="(image, index) in images" :key="index" :image="image" :active="isActive(index)" />
        </div>
        <!-- Slider indicators -->
        <div class="flex w-full items-center justify-center gap-3" v-if="needsCarousel">
            <!-- Left Slider controls -->
            <button
                type="button"
                class="group flex h-full cursor-pointer justify-center outline-none focus:outline-none sm:px-4 hover:md:scale-110"
                @click="prevSlide">
                <ChevronLeft class="size-6 lg:size-8" />
                <span class="sr-only">Previous</span>
            </button>

            <!-- Image Thumbnail controls -->
            <Swiper class="w-full" :breakpoints="swiperOptions.breakpoints" v-if="!thumbsHidden">
                <SwiperSlide v-for="(image, index) in images" :key="`slide-${index}`">
                    <button
                        :key="index"
                        type="button"
                        class="aspect-[4/3] min-h-[108px] w-auto rounded-lg border p-4"
                        :class="[isActive(index) ? 'border-primary-100' : 'border-0']"
                        :aria-current="isActive(index)"
                        :aria-label="`Slide thumbnail ${index + 1}`"
                        @click="goToSlide(index)">
                        <img :src="image" class="size-full object-contain object-center" alt="..." />
                    </button>
                </SwiperSlide>
            </Swiper>

            <!-- Dot Controls -->
            <div class="flex items-center space-x-3" v-if="thumbsHidden">
                <button
                    v-for="(image, index) in images"
                    :key="index"
                    type="button"
                    class="block size-3 rounded-full"
                    :class="[isActive(index) ? 'bg-primary' : 'bg-accent']"
                    :aria-current="isActive(index)"
                    :aria-label="`Slide dot ${index + 1}`"
                    @click="goToSlide(index)"></button>
            </div>

            <!-- Right Slider controls -->
            <button
                type="button"
                class="group flex h-full cursor-pointer items-center justify-center outline-none focus:outline-none sm:px-4 hover:md:scale-110"
                @click="nextSlide">
                <ChevronRight class="size-6 lg:size-8" />
                <span class="sr-only">Next</span>
            </button>
        </div>
    </div>
</template>
