<script setup>
import Button from '@/Components/Forms/Buttons/Button.vue'
import Cart from '@/Components/Icons/Cart.vue'
import Quantity from '@/View/Product/Quantity.vue'
import { useForm } from '@inertiajs/vue3'
import { computed, inject, watch } from 'vue'
import usePriceFormatter from '@/Composables/usePriceFormatter.js'

const priceFormatter = usePriceFormatter()
const route = inject('route')

const props = defineProps({
    price: {
        type: Number,
        default: 0,
    },
    productSku: {
        type: String,
        required: true,
    },
    packingUnit: {
        type: String,
        required: true,
    },
    isSmall: {
        type: Boolean,
        default: false,
    },
    quantity: {
        type: Number,
        required: true,
    },
})

const form = useForm({
    sku: props.productSku,
    quantity: props.quantity,
    packingUnit: props.packingUnit,
})

const emit = defineEmits(['quantityChanged'])
const total = computed(() => props.price * form.quantity)

watch(
    () => props.quantity,
    (value) => (form.quantity = value),
)

watch(
    () => props.packingUnit,
    (value) => (form.packingUnit = value),
)

watch(
    () => form.quantity,
    () => emit('quantityChanged', form.quantity),
)

const handleAddToCart = () => {
    form.post(route('cart.item.create'), {
        preserveScroll: true,
    })
}
</script>

<template>
    <form @submit.prevent="handleAddToCart" class="space-y-4 font-medium sm:flex-nowrap">
        <div class="grid grow gap-x-3 md:gap-x-4" :class="[{ 'grid-cols-2': !isSmall }]">
            <div class="group flex flex-wrap items-center group-focus:ring-2">
                <label aria-label="quantity-label" for="quantity" class="hidden" v-text="Quantity" />
                <Quantity :step="1" :min="1" :max="999" v-model.number="form.quantity" id="quantity" name="quantity" :isSmall="isSmall" />
            </div>
            <div class="flex items-center" v-if="!isSmall">
                <label for="price" class="sr-only">Price</label>
                <input
                    id="price"
                    type="text"
                    :value="priceFormatter.format(total)"
                    class="w-full !rounded-full border !border-primary px-5 py-2 text-end text-2xl font-medium focus:ring-0"
                    readonly />
            </div>
        </div>

        <Button type="submit" full-width="all" color="primary" class="hover:bg-white">
            <template v-slot:start>
                <Cart />
            </template>
            {{ $trans.get('view.product.add-to-cart') }}
        </Button>

        <slot name="description"></slot>

        <div class="mt-2 flex items-center justify-center gap-2" v-if="isSmall">
            <label for="price" class="sr-only">Price</label>
            <input type="text" :value="priceFormatter.format(total)" class="hidden" readonly />

            <span class="text-sm font-light">Total</span>
            <span class="text-lg font-medium">{{ priceFormatter.format(total) }}</span>
        </div>
    </form>
</template>
