<script setup>
import DownloadsWrapper from '@/View/Download/DownloadsWrapper.vue'
import Container from '@/Components/Surfaces/Grid/Container.vue'
import Hero from '@/View/Cms/Content/Hero.vue'
import Row from '@/View/Layouts/Grid/Row.vue'
import MasterLayout from '@/View/Layouts/MasterLayout.vue'
import { Head } from '@inertiajs/vue3'
import { isEmpty } from 'lodash'
import { computed } from 'vue'

const props = defineProps({
    hero: {
        type: Object,
    },
    title: {
        type: String,
    },
    meta_description: {
        type: String,
    },
    documents: {
        type: Object,
    },
})

const hasDocuments = computed(() => !isEmpty(props.documents))
</script>

<template>
    <Head>
        <title>{{ title }}</title>
        <meta name="description" :content="meta_description" />
    </Head>
    <MasterLayout>
        <Container>
            <Row v-if="hero">
                <div class="col-span-12">
                    <Hero
                        :title="hero.title"
                        :body="hero.body"
                        :image="hero.image_url"
                        :subtitle="hero.subtitle"
                        :button-text="hero.button_text"
                        :button-url="hero.button_url"
                        :title-as-heading="true" />
                </div>
            </Row>

            <Row class="mt-12 md:mt-24">
                <div class="col-span-12">
                    <hr class="border border-accent" />
                </div>
            </Row>

            <Row v-if="hasDocuments" class="mt-12 md:mt-24">
                <div class="col-span-12">
                    <DownloadsWrapper :documents="documents" />
                </div>
            </Row>
        </Container>
    </MasterLayout>
</template>
