<script setup>
import { Link } from '@inertiajs/vue3'
import ShoppingCart from '@/Components/Icons/ShoppingCart.vue'
import { useCartStore } from '@/Stores/CartStore.js'
import { computed } from 'vue'

const cartStore = useCartStore()
const itemCount = computed(() => cartStore.getTotalQuantity)
</script>

<template>
    <Link :href="$route('cart.index')" class="relative inline-flex size-6 items-center text-white hover:text-secondary">
        <span class="sr-only">Shopping Cart</span>
        <ShoppingCart class="w-6" />

        <span
            v-if="itemCount > 0"
            class="absolute right-0 top-0 min-h-[16px] min-w-[16px] -translate-y-1/2 translate-x-1/2 rounded-full bg-secondary px-1 text-center text-xxs font-bold text-black">
            {{ itemCount }}
        </span>
    </Link>
</template>
