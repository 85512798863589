<script setup>
import { computed, inject, onMounted, ref } from 'vue'

import { Head } from '@inertiajs/vue3'

import MyAccountsLayout from '@/View/Layouts/MyAccountsLayout.vue'
import DataTable from '@/View/MyAccount/Invoices/DataTable.vue'

import Button from '@/Components/Forms/Buttons/Button.vue'

import { useFetch } from '@/Composables/useFetch.js'

const route = inject('route')
const invoicesData = ref([])
const page = ref(1)
const perPage = ref(10)

const { loading, error, get } = useFetch(route('my-account.customer.invoices'), {
    config: {
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        },
    },
    onSuccess: ({ data }) => {
        if (page.value > 1) {
            invoicesData.value?.push(...data)

            return
        }

        invoicesData.value = data
    },
})

const loadMore = () => {
    page.value++
    getRecords()
}

const getRecords = () => {
    get?.({
        'page': page.value,
        'per-page': perPage.value,
    })
}

onMounted(() => getRecords())

const showViewMoreButton = computed(() => {
    const isLastPage = invoicesData.value.length < page.value * perPage.value

    return !isLastPage || !error
})
</script>

<template>
    <Head>
        <title>{{ $trans.get('page.my-account.sales-order.title') }}</title>
    </Head>
    <MyAccountsLayout :pageTitle="$trans.get('page.my-account.my-account.title')" :subtitle="$trans.get('page.my-account.my-account.subtitle')">
        <h3 class="mb-4 md:mb-8">{{ $trans.get('page.my-account.invoices.title') }}</h3>
        <DataTable :data="invoicesData" :loading="loading" :error="Boolean(error)" />

        <div class="flex justify-center pt-5 text-center">
            <Button v-if="error" id="reload-button" type="button" full-width="lg" color="outlined" :disabled="loading" @click.prevent="getRecords">
                {{ $trans.get('generic.reload') }}
            </Button>
            <Button
                v-if="showViewMoreButton"
                id="view-more-button"
                type="button"
                full-width="lg"
                color="outlined"
                :disabled="loading"
                @click.prevent="loadMore">
                {{ $trans.get('generic.view-more') }}
            </Button>
        </div>
    </MyAccountsLayout>
</template>
