<script setup>
import { isEmpty } from 'lodash'
import { computed } from 'vue'
import { Head } from '@inertiajs/vue3'
import MasterLayout from '@/View/Layouts/MasterLayout.vue'
import Container from '@/Components/Surfaces/Grid/Container.vue'
import Row from '@/Components/Surfaces/Grid/Row.vue'
import Carousel from '@/Components/Surfaces/Carousel/Carousel.vue'
import ProductDetail from '@/View/Product/ProductDetail.vue'

const props = defineProps({
    product: {
        type: Object,
        required: true,
    },
    pricing: {
        type: [Object, null],
        required: true,
    },
})

const placeholderImages = [props.product.thumbnail]

const images = computed(() => {
    return isEmpty(props.product.images) ? placeholderImages : props.product.images
})
</script>

<template>
    <Head>
        <title>{{ product.title }}</title>
        <meta :content="product.meta_description" name="description" />
    </Head>
    <MasterLayout>
        <Container class="mb-12 md:mb-20">
            <Row>
                <section class="col-span-4 space-y-8 md:col-span-12 lg:col-span-6">
                    <div class="relative">
                        <Carousel :images="images" class="block h-auto" />
                    </div>
                </section>

                <section class="col-span-4 mt-6 md:col-span-12 lg:col-span-6 lg:mt-0">
                    <div ref="sidebar" class="top-3 flex flex-col gap-1 px-4 md:gap-4 md:px-12 lg:sticky">
                        <h2>{{ product.title }}</h2>

                        <article class="prose" v-html="product.description" />

                        <ProductDetail
                            :documents="product.documents"
                            :inStock="product.in_stock"
                            :pricing="pricing?.packingUnitPrices"
                            :product-sku="product.sku" />
                    </div>
                </section>
            </Row>
        </Container>
    </MasterLayout>
</template>
