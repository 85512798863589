<script setup>
import { ref, watch, computed } from 'vue'
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@/Composables/External/HeadlessUI/listbox'
import Check from '@/Components/Icons/Check.vue'
import Loading from '@/Components/Icons/Loading.vue'
import ChevronDown from '@/Components/Icons/ChevronDown.vue'

const props = defineProps({
    // <{ label, value }>
    modelValue: {
        type: [Object, null],
        required: true,
    },

    // Array<String> or Array<{label, value, key}>
    options: {
        type: [Array],
        required: true,
    },

    // Trigger when selecting a value from the menu list.
    // (value) => Function: returns a value of the selected menu list.
    onChange: {
        type: Function,
        required: false,
    },

    // Display a loading icon indication
    loading: {
        type: Boolean,
        required: false,
        default: false,
    },
    // Enabled Multi-selection of value
    multiple: {
        type: Boolean,
        required: false,
        default: false,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
})

const model = ref(props.modelValue)
const emit = defineEmits(['update:modelValue'])

watch(
    () => props.modelValue,
    () => {
        model.value = props.modelValue
    },
)

watch(model, () => {
    const { onChange } = props
    emit('update:modelValue', model.value)
    onChange?.(model)
})

const isSelected = computed(() => (value) => {
    if (props.multiple) {
        return model.value.find((element) => element.value === value)
    }

    return model.value?.value === value
})

const onRemoveCode = (code) => (model.value = model.value.filter((item) => item !== code))
</script>

<template>
    <Listbox v-model="model" :multiple="multiple" :disabled="disabled">
        <div class="relative">
            <ListboxButton
                class="relative min-h-[44px] w-full cursor-default space-x-1 space-y-1 rounded border-0 bg-accent py-2 pl-3 pr-10 text-left no-underline ring-primary hover:no-underline focus:outline-none focus:ring-1 sm:text-sm"
                :class="{ 'bg-neutral-200': disabled }">
                <template v-if="multiple">
                    <span
                        v-for="code in modelValue"
                        :key="code.value"
                        class="inline-flex items-center gap-x-0.5 rounded-xl px-2 py-1 text-xs font-medium"
                        :class="[disabled ? 'bg-neutral-200' : 'bg-accent']">
                        {{ code.label }}
                        <button
                            type="button"
                            class="group relative -mr-1 ml-1 size-3.5 rounded-xl"
                            :class="{ 'hover:bg-primary': !disabled }"
                            @click="() => !disabled && onRemoveCode(code)">
                            <span class="sr-only">Remove</span>
                            <svg viewBox="0 0 14 14" class="size-3.5 stroke-black" :class="{ 'group-hover:stroke-white': !disabled }">
                                <path d="M4 4l6 6m0-6l-6 6" />
                            </svg>
                            <span class="absolute -inset-1" />
                        </button>
                    </span>
                </template>
                <span class="block truncate">{{ model?.label ? model?.label : model }}</span>

                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <Loading v-if="loading" class="-mr-1 size-5 text-primary" aria-hidden="true" />
                    <ChevronDown v-else class="size-5" aria-hidden="true" />
                </span>
            </ListboxButton>

            <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <ListboxOptions
                    as="div"
                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 after:list-none focus:outline-none sm:text-sm">
                    <ListboxOption v-slot="{ active }" v-for="option in options" :key="option.value" :value="option" as="div">
                        <div
                            :class="[
                                active ? 'bg-neutral-100' : 'text-neutral-500',
                                'relative cursor-default select-none py-2 pl-10 pr-4 ring-primary focus:ring-1',
                            ]">
                            <span :class="[isSelected(option.value) ? 'font-medium text-neutral-700' : 'font-light', 'block truncate']">
                                {{ option.label ? option.label : option }}
                            </span>
                            <span v-if="isSelected(option.value)" class="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                                <Check class="size-5" aria-hidden="true" />
                            </span>
                        </div>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </div>
    </Listbox>
</template>
