<script setup>
import { useFetch } from '@/Composables/useFetch.js'

// ** Component Imports
import Download from '../../Components/Icons/Download.vue'
import Loading from '@/Components/Icons/Loading.vue'

const props = defineProps({
    fileUrl: {
        type: String,
        required: true,
    },
    fileName: {
        type: String,
        default: '',
    },
    buttonName: {
        type: String,
        required: false,
        default: '',
    },
    srOnly: {
        type: Boolean,
    },
})

const { loading, get } = useFetch(props.fileUrl, {
    config: {
        responseType: 'blob',
    },
    onSuccess: ({ data }) => saveBlob(data),
})

const saveBlob = (data) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', props.fileName)
    document.body.appendChild(link)
    link.click()

    window.URL.revokeObjectURL(url)
    document.body.removeChild(link)
}

const startDownload = async () => get()
</script>

<template>
    <a
        :href="fileUrl"
        :download="fileName"
        @click.prevent="startDownload"
        class="group flex cursor-pointer items-center gap-2 text-sm text-neutral-500 focus:text-primary focus:no-underline">
        <Loading v-if="loading" class="size-4" />
        <Download v-else class="size-4" />
        <span class="hidden sm:block" :class="{ 'sr-only': srOnly }">{{ buttonName }}</span>
    </a>
</template>
