<script setup>
// ** Framework
import { Head } from '@inertiajs/vue3'
import { isEmpty } from 'lodash'
import { computed } from 'vue'
import Card from '../Components/Surfaces/Card/Card.vue'
import Hero from '../View/Cms/Content/Hero.vue'
import Container from '../View/Layouts/Grid/Container.vue'
import Row from '../View/Layouts/Grid/Row.vue'

import MasterLayout from '../View/Layouts/MasterLayout.vue'

const props = defineProps(['featured_block_one', 'featured_block_two', 'featured_block_three', 'hero', 'title', 'meta_description'])

const hasFeaturedBlocks = computed(() => {
    return !isEmpty(props.featured_block_one) || !isEmpty(props.featured_block_two) || !isEmpty(props.featured_block_three)
})
</script>

<template>
    <Head>
        <title>{{ title }}</title>
        <meta name="description" :content="meta_description" />
    </Head>

    <MasterLayout>
        <Container>
            <Row v-if="hero">
                <div class="col-span-12">
                    <Hero
                        :title="hero.title"
                        :body="hero.body"
                        :image="hero.image_url"
                        :subtitle="hero.subtitle"
                        :button-text="hero.button_text"
                        :button-url="hero.button_url"
                        :title-as-heading="true" />
                </div>
            </Row>

            <Row v-if="hasFeaturedBlocks" class="my-6 gap-y-8 md:my-12">
                <div class="col-span-full md:col-span-6 lg:col-span-4" v-if="featured_block_one">
                    <Card
                        :title="featured_block_one.title"
                        :body="featured_block_one.body"
                        :image="featured_block_one.image_url"
                        :button-url="featured_block_one.button_url"
                        :button-text="featured_block_one.button_text" />
                </div>

                <div class="col-span-full md:col-span-6 lg:col-span-4" v-if="featured_block_two">
                    <Card
                        :title="featured_block_two.title"
                        :body="featured_block_two.body"
                        :image="featured_block_two.image_url"
                        :button-url="featured_block_two.button_url"
                        :button-text="featured_block_two.button_text" />
                </div>

                <div class="col-span-full md:col-span-6 lg:col-span-4" v-if="featured_block_three">
                    <Card
                        :title="featured_block_three.title"
                        :body="featured_block_three.body"
                        :image="featured_block_three.image_url"
                        :button-url="featured_block_three.button_url"
                        :button-text="featured_block_three.button_text" />
                </div>
            </Row>
        </Container>
    </MasterLayout>
</template>
