<script setup>
import ChevronDown from '@/Components/Icons/ChevronDown.vue'
import { uniqueId } from 'lodash'
import { computed, ref } from 'vue'

defineProps({
    question: {
        type: String,
        required: true,
    },
    answer: {
        type: String,
        required: true,
    },
})

const open = ref(false)
const buttonID = computed(() => uniqueId('accordion-button-'))
const ariaID = computed(() => uniqueId('accordion-aria-'))

const handleClick = () => {
    open.value = !open.value
}
</script>

<template>
    <div class="flex gap-4 rounded-xl bg-white p-4 md:gap-6 md:p-8">
        <div>
            <div @click="handleClick" class="inline-flex size-10 cursor-pointer items-center justify-center rounded-xl bg-secondary text-black">
                <ChevronDown class="transition-all" :class="{ 'rotate-180': open }" />
            </div>
        </div>
        <div>
            <button
                type="button"
                :aria-expanded="open"
                :id="buttonID"
                :aria-controls="ariaID"
                @click="handleClick"
                class="mt-2 text-left font-semibold">
                {{ question }}
            </button>

            <div
                class="grid transition-[grid-template-rows] duration-200"
                :class="[open ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]']"
                role="region"
                :id="ariaID"
                :aria-labelledby="buttonID">
                <div class="overflow-hidden">
                    <div v-html="answer" class="mt-4 text-gray-500 md:mt-6"></div>
                </div>
            </div>
        </div>
    </div>
</template>
