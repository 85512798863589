<script setup>
import { computed, inject } from 'vue'

import Table from '@/Components/Table/Table.vue'
import TableRow from '@/Components/Table/TableRow.vue'
import TableBody from '@/Components/Table/TableBody.vue'
import TableHeader from '@/Components/Table/TableHeader.vue'
import TableDataCell from '@/Components/Table/TableDataCell.vue'

import UrlDownloader from '@/View/MyAccount/UrlDownloader.vue'
import StatusPill from '@/View/MyAccount/StatusPill.vue'

import usePriceFormatter from '@/Composables/usePriceFormatter.js'
import { formatDayMonthShort, formatStockPeriodLocalized } from '@/Composables/useDateFormatter.js'

import { isEmpty } from 'lodash'

const priceFormatter = usePriceFormatter()
const trans = inject('trans')

const props = defineProps({
    data: {
        type: Array,
        required: true,
    },
    loading: {
        type: Boolean,
    },
    error: {
        type: Boolean,
    },
})

const hasInvoices = computed(() => !isEmpty(props.data))

const columns = [
    { name: trans.get('generic.options'), srOnly: true },
    { name: trans.get('page.my-account.invoices.table-column.invoice-number'), class: 'pl-4 md:pl-0' },
    { name: trans.get('page.my-account.invoices.table-column.status'), class: '!text-left !md:text-right' },
    { name: trans.get('page.my-account.invoices.table-column.total'), class: 'hidden lg:table-cell' },
    { name: trans.get('page.my-account.invoices.table-column.due-date'), class: 'hidden md:table-cell' },
    { name: trans.get('page.my-account.invoices.table-column.order-number'), class: 'hidden lg:table-cell' },
    { name: trans.get('page.my-account.invoices.table-column.reference'), class: 'hidden md:table-cell' },
    { name: trans.get('page.my-account.invoices.table-column.creation-date'), class: 'hidden md:table-cell' },
]

const statusList = {
    open: {
        class: 'bg-warning',
        name: trans.get('page.my-account.invoices.status.open'),
    },
    expired: {
        class: 'bg-danger-600 text-white',
        name: trans.get('page.my-account.invoices.status.open'),
    },
    paid: {
        class: 'bg-primary text-white',
        name: trans.get('page.my-account.invoices.status.paid'),
    },
}

const noResult = computed(() => !hasInvoices.value && !props.loading)
</script>

<template>
    <div class="mt-8 flow-root">
        <div class="-mx-6 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-12">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <Table :full-height="!hasInvoices">
                    <TableHeader :columns="columns" />
                    <TableBody
                        :column-length="columns.length"
                        :no-result="noResult"
                        :loading="loading"
                        :error-occurred="error"
                        :empty-message="$trans.get('page.my-account.packing-slips.table-component.no-results')">
                        <template v-for="invoice in data" :key="invoice.id">
                            <TableRow>
                                <TableDataCell>
                                    <template v-if="invoice.report && invoice.id">
                                        <UrlDownloader
                                            :button-name="$trans.get('page.my-account.invoices.option.download')"
                                            sr-only
                                            :file-name="`Invoice-${invoice.id}.pdf`"
                                            :file-url="$route('profit.invoices.file-download', { fileId: invoice.report, entityId: invoice.id })" />
                                    </template>
                                </TableDataCell>
                                <TableDataCell class="font-medium md:pl-0">
                                    <div class="flex gap-1">
                                        <div>
                                            <span class="text-black">{{ invoice.id }}</span>
                                            <dl class="font-light lg:hidden">
                                                <dt class="sr-only lg:hidden">
                                                    {{ $trans.get('page.my-account.packing-slips.table-column.order-number') }}
                                                </dt>
                                                <dd class="lg:hidden">{{ invoice.salesOrderId }}</dd>
                                                <dt class="sr-only lg:hidden">
                                                    {{ $trans.get('page.my-account.invoices.table-column.total') }}
                                                </dt>
                                                <dd class="mt-1 lg:hidden">{{ priceFormatter.format(invoice.grandTotal) }}</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </TableDataCell>
                                <TableDataCell class="w-1/2 sm:w-auto sm:pl-0">
                                    <div class="flex w-full justify-between">
                                        <div class="grow items-center">
                                            <dt class="sr-only sm:hidden">
                                                {{ $trans.get('page.my-account.invoices.table-column.due-date') }}
                                            </dt>
                                            <dd class="md:hidden">{{ formatStockPeriodLocalized(invoice.dueDate) }}</dd>
                                            <dt class="sr-only sm:hidden">
                                                {{ $trans.get('page.my-account.invoices.table-column.reference') }}
                                            </dt>
                                            <dd class="md:hidden">{{ invoice.reference }}</dd>
                                            <dt class="sr-only sm:hidden">
                                                {{ $trans.get('page.my-account.sales-order.table-column.date-created') }}
                                            </dt>
                                            <StatusPill :status="invoice.status" :status-list="statusList" />
                                        </div>
                                    </div>
                                </TableDataCell>
                                <TableDataCell class="hidden lg:table-cell">
                                    {{ priceFormatter.format(invoice.grandTotal) }}
                                </TableDataCell>
                                <TableDataCell class="hidden md:table-cell">
                                    {{ formatDayMonthShort(invoice.dueDate) }}
                                </TableDataCell>
                                <TableDataCell class="hidden lg:table-cell">
                                    {{ invoice.salesOrderId }}
                                </TableDataCell>
                                <TableDataCell class="hidden max-w-64 md:table-cell">
                                    {{ invoice.reference }}
                                </TableDataCell>
                                <TableDataCell class="hidden md:table-cell">
                                    {{ formatDayMonthShort(invoice.creationDate) }}
                                </TableDataCell>
                            </TableRow>
                        </template>
                    </TableBody>
                </Table>
            </div>
        </div>
    </div>
</template>
