<script setup>
import CrossThin from '@/Components/Icons/CrossThin.vue'
import { InformationCircleIcon, ExclamationTriangleIcon, CheckCircleIcon, ExclamationCircleIcon } from '@/Composables/External/HeroIcons/outline'
import { computed, ref } from 'vue'

const props = defineProps({
    type: {
        type: String,
        default: 'default',
    },
    dismissible: {
        type: Boolean,
        default: false,
    },
})

const show = ref(true)

const Icon = computed(() => {
    switch (props.type) {
        case 'default':
        case 'info':
            return InformationCircleIcon
        case 'warning':
            return ExclamationTriangleIcon
        case 'success':
            return CheckCircleIcon
        case 'danger':
            return ExclamationCircleIcon
    }

    return InformationCircleIcon
})
</script>

<template>
    <div
        v-if="show"
        :class="{
            'bg-neutral-900 text-white': type === 'default',
            'bg-info-200 text-info-700': type === 'info',
            'bg-warning-200 text-warning-800': type === 'warning',
            'bg-danger-200 text-danger-800': type === 'danger',
            'bg-success-200 text-success-800': type === 'success',
            'pr-8': dismissible,
        }"
        class="relative rounded py-2 text-sm font-medium">
        <div class="container mx-auto flex items-center gap-4 px-3 [&>p]:mb-0">
            <Icon class="h-6 w-6 min-w-[24px]" />

            <slot></slot>
        </div>

        <button v-if="dismissible" @click="show = false" class="absolute right-2 top-2"><CrossThin /></button>
    </div>
</template>
