<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { usePage } from '@inertiajs/vue3'
import { isEmpty, pullAt } from 'lodash'

import Toast from '@/Components/Feedback/Toaster/Toast.vue'

const toasts = ref([])
const flash = computed(() => usePage().props?.flash?.toasts)

watch(flash, () => mapToasters())

const onHide = (toast, index) => {
    toasts.value[index].visible = false
    setTimeout(() => {
        pullAt(toasts.value, index)
    }, 1000)
}

const mapToasters = () => {
    if (isEmpty(flash.value)) {
        return
    }

    flash.value.forEach((toast) => {
        toasts.value.push({
            message: toast.message,
            success: toast.success,
            visible: true,
        })
    })
}

onMounted(() => mapToasters())
</script>
<template>
    <div class="fixed bottom-0 right-0 z-[99] w-full px-3 pb-3 sm:w-[370px]">
        <Toast
            v-for="(toast, index) in toasts"
            :position="index + 1"
            :visible="toast.visible"
            :key="toast"
            :success="toast.success"
            @toast-hidden="() => onHide(toast, index)">
            {{ toast.message }}
        </Toast>
    </div>
</template>
