<script setup>
import Toaster from '@/Components/Feedback/Toaster/Toaster.vue'
import { twMerge } from '@/Composables/External/twMerge.js'
import { useCartStore } from '@/Stores/CartStore.js'
import Footer from '@/View/Layouts/Sections/Footer.vue'
import { usePage } from '@inertiajs/vue3'
import { computed, watch } from 'vue'
import Header from './Sections/Header.vue'

defineProps({
    wrapperClass: {
        type: String,
    },
})

const cartStore = useCartStore()
const cart = computed(() => usePage()?.props?.cart)

watch(cart, () => cartStore.setCart(cart.value), { immediate: true })
</script>
<template>
    <div class="flex min-h-[100vh] flex-col">
        <a href="#main-content" class="sr-only focus:not-sr-only">Skip to main content</a>

        <Header />

        <main id="main-content">
            <div :class="twMerge('my-14 md:my-16', wrapperClass)">
                <slot></slot>
            </div>
        </main>

        <Footer class="mt-auto" />

        <Toaster />
    </div>
</template>
