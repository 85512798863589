<script setup>
// ** Vue

// ** Third party
import { last, orderBy } from 'lodash'
import usePriceFormatter from '@/Composables/usePriceFormatter.js'

const priceFormatter = usePriceFormatter()

// ** Props
const props = defineProps({
    pricing: {
        type: Object,
        required: true,
    },
    onClick: {
        type: Function,
        required: false,
    },
    inProductFeed: {
        type: Boolean,
        default: false,
    },
})

const sortPricing = (pricing) => {
    const orderedPricing = orderBy(pricing, 'quantity', 'desc')

    return props.inProductFeed ? [last(orderedPricing)] : orderedPricing
}

const onClickPrice = (quantity, pricingObject) => props.onClick?.(quantity, pricingObject)
</script>

<template>
    <div class="my-3">
        <table class="table--hover table w-full border-none text-sm">
            <thead>
                <tr class="clear text-neutral-400">
                    <th class="text-start">{{ $trans.get('view.product.quantity') }}</th>
                    <th class="!text-end">{{ $trans.get('view.product.unit-price') }}</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(pricingObject, index) in pricing" :key="index">
                    <tr>
                        <th colspan="2" scope="colgroup" class="pointer-events-none bg-gray-50 text-left text-sm font-semibold text-gray-900">
                            {{
                                $trans.choice('view.product.tier-price.heading', pricingObject.itemsPerPackingUnit, {
                                    tier: pricingObject.packingUnit,
                                    units: pricingObject.itemsPerPackingUnit,
                                })
                            }}
                        </th>
                    </tr>

                    <tr
                        v-for="(price, index) in sortPricing(pricingObject.tierPrices)"
                        :key="index"
                        class="cursor-pointer border-t border-accent"
                        @click="() => onClickPrice(price.quantity, pricingObject)">
                        <td class="!p-1 sm:!p-2">
                            <span class="text-neutral-500">
                                {{
                                    $trans.choice('view.product.from-unit', price.quantity, {
                                        units: price.quantity,
                                    })
                                }}
                            </span>
                        </td>

                        <td class="!p-1 !text-end text-sm font-medium text-neutral-900 sm:!p-2">
                            {{ priceFormatter.format(price.price) }}
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>
